import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import Store from 'store';

import Home from './screens/home/Home';
import Login from './screens/login/Login';
import Form from './screens/form/Form';

import 'react-tippy/dist/tippy.css';
import { GroupList, GroupDetail, EmailTemplate, CreateEmail, DetailEmail, SMSTemplate, CreateSMS, DetailSMS, ListAdmin, CreateUserAdmin, AdminDetail, ForgotPassword, Invite, Settings, Compliance } from 'screens';

let RouteHandler = observer(({ path, component, ...props }) => {
  if (!props.auth || Store.User.isAuthen()) {
    return (
      <Route path={path} component={component} exact {...props} />
    );
  }
  return <Redirect to="/login" />;
});

const Router = () => {
  const [ready, setReady] = useState(false);

  const waitReady = async () => {
    await Store.User.onReady();
    console.log('ready');
    setReady(true);
  };

  useEffect(() => {
    waitReady();
  }, [])

  if (!ready) return null;
  return (
    <BrowserRouter>
      <Switch>
        <RouteHandler path="/login" component={Login} />
        <RouteHandler path="/forgot-password" component={ForgotPassword} />
        <RouteHandler auth path="/departments/:name" component={GroupList} />
        <RouteHandler auth path="/group/:id/:name" component={GroupDetail} />
        <RouteHandler path="/form/:id" component={Form} />
        <RouteHandler auth path="/email-templates" component={EmailTemplate} />
        <RouteHandler auth path="/create-email-template" component={CreateEmail} />
        <RouteHandler auth path="/email-template/:id/:name" component={DetailEmail} />
        <RouteHandler auth path="/sms-templates" component={SMSTemplate} />
        <RouteHandler auth path="/create-sms-template" component={CreateSMS} />
        <RouteHandler auth path="/sms-template/:id/:name" component={DetailSMS} />
        <RouteHandler auth path="/user-admins" component={ListAdmin} />
        <RouteHandler auth path="/create-user-admin" component={CreateUserAdmin} />
        <RouteHandler auth path="/user-admin/:id" component={AdminDetail} />
        <RouteHandler path="/invite/:token" component={Invite} />
        <RouteHandler auth path="/settings" component={Settings} />
        <RouteHandler auth path="/compliance" component={Compliance} />
        <RouteHandler auth path="/" component={Home} />
        
      </Switch>
    </BrowserRouter>
  );
}

export default Router;
