import React from 'react';
import { withRouter } from 'react-router-dom';

import './Container.scss';

import Sidebar from './Sidebar';

const Container = (props) => {
  return (
    <div className="app-container position-relative d-flex">
      <Sidebar />
      <div className="flex1 h-100 content-container">
        {props.children}
      </div>
    </div>
  );
};

export default Container;
