import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Input, Select, Button, AddEmailNotiModal } from 'components';
import Store from 'store';
import * as SVG from 'imgs/SVG';
import moment from 'moment';
import classnames from 'classnames';
import LinesEllipsis from 'react-lines-ellipsis';
import TextTruncate from 'react-text-truncate';
import {
  Tooltip,
} from 'react-tippy';

import './Contact.scss';

const EmailNotification = ({ detail }) => {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [tempEmail, setTempEmail] : any = useState('');
  const [notiEmailList, setNotiEmailList] = useState([]);

  const getData = async () => {
    const [res, err] = await Store.Group.listEmailNoti(detail.id);
    if(err) return alert(String(err));
    if (!res.success && res.message) return alert("ERROR: " + res.message);
    if (res.data) {
      setNotiEmailList(res.data);
    }
  };

  useEffect(() => {
    getData();
  }, [])

  // const onAddContact = async (val) => {
  //   const newContact = notiEmailList.slice();
  //   newContact.unshift(val);
  //   onChangeContact(newContact);
  // }

  const removeEmailNoti = async ({ id }) => {
    const [res, err] = await Store.Group.deleteEmailNoti(id);
    if(err) return alert(String(err));
    if (!res.success && res.message) return alert("ERROR: " + res.message);
    getData();
  }

  return (
    <section className="mt-3 p-l-30">
      {/* <div>
        <span onClick={() => setOpenAddModal(true)} className={classnames("span-btn")}>Add an email</span>
      </div> */}
      <table className="table contact-table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Email</th>
            <th scope="col" style={{ textAlign: 'right' }} className="pr-4"><span onClick={() => setOpenAddModal(true)} className={classnames("span-btn")}>+ Add an email</span></th>
          </tr>
        </thead>
        <tbody>
          {!!notiEmailList && notiEmailList.map((val, i) => (
            <tr key={"contact-item-" + i}>
              <th scope="row">{i+1}</th>
              <td>{val.email}</td>
              <td style={{ textAlign: 'right' }} className="pr-4">
                {!Store.User.viewOnly() && (
                  <SVG.Remove onClick={() => removeEmailNoti(val)} style={{ fill: '#44B0D1', width: 25, cursor: 'pointer' }} />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <AddEmailNotiModal
        open={openAddModal}
        onClose={() => setOpenAddModal(false)}
        detail={detail}
        onAdd={() => getData()}
      />
    </section>
  );
};

export default EmailNotification;