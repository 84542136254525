import React from 'react';
import classnames from 'classnames';

import './Input.scss';

export default (props) => {
  return (
    <input
    {...props}
    className={classnames("ra-input input-control input-group", props.className)}
    />
  );
}