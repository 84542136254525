import React, { useState, useEffect } from 'react';
import Store from 'store';
import Modal from '../modal/Modal';
import Button from '../button/Button';
import Input from '../input/Input';
import Select from '../select/Select';
import './EditContactModal.scss';
import moment from 'moment';

import FormElement from '../form-element/FormElement';

interface Props {
  open: boolean;
  onClose(): void;
  onEdit?(edittedData : any): void;
  data: any;
  detail: any;
}

const EditContactModal = (props: Props) => {

  const [edittedData, setEdittedData] : any = useState({});

  const [formData, setFormData] = useState([]);

  useEffect(() => {
    if (!props.open) {
      setEdittedData({});
    } else {
      console.log(props.data);
      setEdittedData({
        ...props.data,
        ...props.data.fields,
      });
      console.log({
        ...props.data,
        ...props.data.fields,
      });
      const findForm = props.detail.forms.find(val => val.link === props.data.from);
      if (findForm) setFormData(findForm.fields);
      else setFormData([{"name":"Name","isRequired":true,"type":"SINGLE_LINE_TEXT","options":""},{"name":"Phone","isRequired":true,"type":"SINGLE_LINE_TEXT","options":""},{"name":"Email","isRequired":true,"type":"SINGLE_LINE_TEXT","options":""}])
    }
  }, [props.open]);

  const onSubmitEdit = async () => {
    console.log("onSubmitEdit", edittedData);
    if (edittedData.Name)
      edittedData.name = edittedData.Name
    if (edittedData.Phone)
      edittedData.phone = edittedData.Phone
    if (edittedData.Email)
      edittedData.email = edittedData.Email

    const values = { ...edittedData };
    const name = values.Name || values.name || '';
    const phone = values.Phone || values.phone || '';
    const email = values.Email || values.email || '';
    const doorCode = values.doorCode || ''; // for Tenant Group
    const newValues = Object.assign({}, values);
    delete newValues.Name;
    delete newValues.name;
    delete newValues.Phone;
    delete newValues.phone;
    delete newValues.Email;
    delete newValues.email;
    delete newValues.id;
    delete newValues.from;
    delete newValues.enable;
    delete newValues.fields;
    delete newValues.doorCode;
    const fields = JSON.stringify(newValues);
    const [res, err] = await Store.Contact.update({ id: props.data.id, name, phone, email, fields, doorCode });
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    console.log("props.onEdit");
    props.onEdit(edittedData);
    props.onClose();
  };

  console.log('formData', formData);

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <h3>Edit Contact: {props.data.name}</h3>
      <div className="list-tracking-container mt-2">
        {!!formData && formData.map((field, index) => {
          return (
            <FormElement
              key={"field"+index}
              {...field}
              value={edittedData[field.name] || edittedData[field.name.toLowerCase()]}
              onChange={value => {
                setEdittedData({
                  ...edittedData,
                  [field.name]: value,
                })
              }}
            />
          );
        })}
        {props.detail && props.detail.department && props.detail.department.name === "Tenant" && (
          <FormElement
            type="SINGLE_LINE_TEXT"
            isRequired={false}
            options={""}
            name="Door Code/Key No"
            value={edittedData.doorCode}
            onChange={value => {
              setEdittedData({
                ...edittedData,
                doorCode: value,
              })
            }}
          />
        )}
        <div className="form-group">
          <Button green onClick={onSubmitEdit}>Submit</Button>
        </div>
      </div>
    </Modal>
  );
};

export default EditContactModal;
