import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Container, Button, Select, Input } from 'components';
import moment from 'moment';
import './ListAdmin.scss';
import Store from 'store';
import { Eye, Edit } from 'imgs/SVG';

const AdminDetail = (props) => {
  const [createData, setCreateData] : any = useState({});
  const [listGroup, setListGroup] : any = useState([]);
  const [accessGroup, setAccessGroup] : any = useState([]);

  const onSubmitForm = async e => {
    e.preventDefault();
    const { firstName, lastName, password, email, viewOnly, id } = createData;
    console.log({ firstName, lastName, password, email, viewOnly });
    if (!firstName || !lastName || !email) {
      return alert("Please fill all fields");
    }
    let formsAccess = '';
    if (!!accessGroup) {
      accessGroup.forEach((g, index) => {
        formsAccess += '.'+ g.value;
        if (index === accessGroup.length - 1) {
          formsAccess += '.';
        }
      });
    }
    const [res, err] = await Store.User.createAdmin({ firstName, lastName, password, email, viewOnly, id, formsAccess });
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    if (id === Store.User.userData.id) {
      Store.User.userData = {
        ...Store.User.userData,
        firstName, lastName, password, email, viewOnly, formsAccess,
      }
    }
    props.history.push('/user-admins');
  }

  const getGroupData = async () => {
    const [res, err] = await Store.Group.getAllGroups();
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    if (res.data) {
      const list = [];
      res.data.forEach((val) => {
        const findDepartmentIndex = list.findIndex(item => item.label === val.department.name);
        if (findDepartmentIndex === -1) {
          list.push({
            label: val.department.name,
            options: [
              { label: val.name, value: val.id },
            ]
          })
        } else {
          list[findDepartmentIndex].options.push({ label: val.name, value: val.id });
        }
      });
      getDetail(list);
    }
  };

  const getDetail = async (list) => {
    setListGroup(list);
    const id = props.match.params.id;
    const [res, err] = await Store.User.detail(id);
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    setCreateData(res.data);
    const formsAccessIDs = res.data.formsAccess.split('.').filter(val => !!val).map(val => +val);
    const newAccessGroup = [];
    list.forEach((val) => {
      val.options.forEach(item => {
        if (formsAccessIDs.includes(item.value)) {
          newAccessGroup.push(item);
        }
      })
    });
    setAccessGroup(newAccessGroup);
  };

  useEffect(() => {
    getGroupData();
  }, []);

  return (
    <Container>
      <div className="group-container">
        <section style={{ padding: 30 }}>
          <div className="d-flex align-items-center mt-3">
            <h3 className="heading mr-4">User Admin > {createData.firstName} {createData.lastName}</h3>
          </div>
        </section>
        <form className="mt-3 p-l-30" onSubmit={onSubmitForm}>
          <div className="row form-group">
            <div className="col-2">
              First Name:
            </div>
            <div className="col-12 col-md-6">
              <Input value={createData.firstName} onChange={e => setCreateData({ ...createData, firstName: e.target.value })} />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-2">
              Last Name:
            </div>
            <div className="col-12 col-md-6">
              <Input value={createData.lastName} onChange={e => setCreateData({ ...createData, lastName: e.target.value })} />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-2">
              Email:
            </div>
            <div className="col-12 col-md-6">
              <Input value={createData.email} onChange={e => setCreateData({ ...createData, email: e.target.value })} />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-2">
              Password:
            </div>
            <div className="col-12 col-md-6">
            <Input type="password" value={createData.password} onChange={e => setCreateData({ ...createData, password: e.target.value })} />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-2">
              View Only:
            </div>
            <div className="col-12 col-md-4">
              <Select
                value={createData.viewOnly ? { label: 'YES', value: true} : { label: 'NO', value: false}}
                options={[
                  { label: 'YES', value: true},
                  { label: 'NO', value: false},
                ]}
                isClearable={false}
                onChange={(data) => setCreateData({ ...createData, viewOnly: data.value })}
              />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-2">
              Form Access:
            </div>
            <div className="col-12 col-md-4">
              <Select
                placeholder="Select or Search for groups.."
                value={accessGroup}
                options={listGroup}
                isClearable={false}
                isMulti
                isSearchable
                onChange={(data) => setAccessGroup(data)}
                styles={{
                  option: (base, state) => ({ ...base, paddingLeft: 40 }),
                }}
              />
              <p className="mt-2">Leave it blank to have all forms access</p>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-12">
              <Button submit green>Save</Button>
            </div>
          </div>
        </form>
      </div>
    </Container>
  );
}

export default withRouter(observer(AdminDetail));