import React, { useState, useEffect } from 'react';
import Store from 'store';
import Modal from '../modal/Modal';
import Button from '../button/Button';
import Input from '../input/Input';
import Select from '../select/Select';
import './EditContactModal.scss';

interface Props {
  open: boolean;
  onClose(): void;
  groupId: string;
}

const SendSMSNow = (props: Props) => {
  const [sending, setSending] = useState(false)
  const [createData, setCreateData]: any = useState({});
  const [useTwilio, setUseTwilio] = useState(false);

  const smsProviderOptions = [
    { label: 'AQL', value: false, },
    { label: 'Twilio', value: true },
  ]

  const onSubmitForm = async (e) => {
    e.preventDefault();
    const { content } = createData;
    if (!content) {
      return alert('Please fill all form');
    }
    setSending(true);
    const [res, err] = await Store.Schedule.sendSMSNow({
      groupId: props.groupId,
      content,
      useTwilio
    });
    setSending(false);
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    else {
      alert('Sent successfully');
      props.onClose();
    }
  };

  return (
    <Modal open={props.open} onClose={props.onClose} containerStyle={{ width: '70vw' }}>
      <h3>Send SMS to all submitted users</h3>
      <div className="mt-2">
        <form className="mt-3 p-l-30" onSubmit={onSubmitForm}>
          <div className="row form-group" style={{ height: 300 }}>
            <div className="col-2">
              Content:
            </div>
            <div className="col-12 col-md-6">
              <textarea
                style={{ height: 300, width: '100%', borderRadius: 8, borderColor: '#BFBFBF' }}
                value={createData.content}
                onChange={e => setCreateData({ ...createData, content: e.target.value })}
              />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-2">
              Send Via:
            </div>
            <div className="col-12 col-md-6">
              <Select
                options={smsProviderOptions}
                required
                value={smsProviderOptions.find(val => val.value == useTwilio)}
                onChange={data => {
                  setUseTwilio(data.value);
                }}
              />
            </div>
          </div>

          <p>Tags Available are: [name], [email], [phone]</p>
          <div className="row form-group">
            <div className="col-12">
              <Button submit green>{sending ? 'Sending...' : 'Submit'}</Button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default SendSMSNow;
