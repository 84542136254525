import React, { useState, useEffect } from 'react';
import Store from 'store';
import Modal from '../modal/Modal';
import Button from '../button/Button';
import Input from '../input/Input';
import Select from '../select/Select';

interface Props {
  id: number,
  name: string,
  open: boolean;
  useTwilio: boolean;
  onClose(): void;
  onUpdated?(): void;
}

const EditGroupModal = (props : Props) => {
  const [createData, setCreateData] = useState({ name: props.name });
  const [useTwilio, setUseTwilio] = useState(props.useTwilio);
  useEffect(() => {
    setCreateData({
      name: props.name,
    })
  }, [props.name]);
  useEffect(() => {
    setUseTwilio(props.useTwilio);
  }, [props.useTwilio]);
  const createGroup = async () => {
    if (!createData.name) {
      return alert('Please fill all the fields');
    }
    const [res, err] = await Store.Group.editGroup({
      name: createData.name,
      id: props.id,
      useTwilio: useTwilio,
    });
    if (err) return alert('Error: ' + String(err));
    props.onClose();
    setCreateData({ name: '' });
    !!props.onUpdated && props.onUpdated();
  }

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <form className="form-group">
        <h3 className="heading">Edit group</h3>
        <div className="form-group mt-3">
          <Input placeholder="Group Name" value={createData.name} onChange={e => setCreateData({ ...createData, name: e.target.value })} />
        </div>
        <div className="form-group mt-3">
          <div className="float-left">
            <label className="checkbox">
              <input type="checkbox" checked={useTwilio} onChange={e => setUseTwilio(e.target.checked)} />
              <span className="checkmark" style={{ marginRight: 10 }}></span>
              Use Twilio
            </label>
          </div>
          <Button green className="float-right" style={{ width: 200 }} onClick={createGroup}>
            Update
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default EditGroupModal;
