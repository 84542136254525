import React from 'react';
import Select from 'react-select';
import RequiredSelect from './RequiredSelect';

interface Props {
  required?: boolean,
  [key : string]: any;
}

export default (props : Props) => {
  let style : any = {};
  if (props.width) {
    style.width = props.width;
  }
  return (
    <div className={props.className} style={style}>
      <RequiredSelect {...props} SelectComponent={Select} />
    </div>
  );
}