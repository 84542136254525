import React, { useState, useEffect } from 'react';
import Store from 'store';
import Modal from '../modal/Modal';
import Button from '../button/Button';
import Input from '../input/Input';
import Select from '../select/Select';
import moment from 'moment';

interface Props {
  open: boolean;
  onClose(): void;
}

const GetInviteLinkModal = (props : Props) => {
  const [viewOnly, setViewOnly] = useState(false);
  const [link, setLink] = useState('');

  const [listGroup, setListGroup] : any = useState([]);
  const [accessGroup, setAccessGroup] : any = useState([]);

  const getData = async () => {
    const [res, err] = await Store.Group.getAllGroups();
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    if (res.data) {
      const list = [];
      res.data.forEach((val) => {
        const findDepartmentIndex = list.findIndex(item => item.label === val.department.name);
        if (findDepartmentIndex === -1) {
          list.push({
            label: val.department.name,
            options: [
              { label: val.name, value: val.id },
            ]
          })
        } else {
          list[findDepartmentIndex].options.push({ label: val.name, value: val.id });
        }
      });
      setListGroup(list);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const selectOptions = [
    { value: true, label: 'YES' },
    { value: false, label: 'NO' }
  ];

  const copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  const onSubmitted = async (e) => {
    e.preventDefault();
    if (link) {
      copyToClipboard(link);
      props.onClose();
      setAccessGroup([]);
      setLink('');
      return;
    };
    let formsAccess = '';
    if (!!accessGroup) {
      accessGroup.forEach((g, index) => {
        formsAccess += '.'+ g.value;
        if (index === accessGroup.length - 1) {
          formsAccess += '.';
        }
      });
    }
    const [res, err] = await Store.User.createInviteLink(viewOnly, formsAccess);
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    if (res.data) {
      setLink(`${window.location.protocol}//${window.location.host}/invite/${res.data.token}`);
    }
  };

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <form className="form-group" onSubmit={onSubmitted}>
        <h3 className="heading">Get invite link</h3>

        <div className="form-group mt-3">
          <label>Do you want this user to have view only permission?</label>
          <Select
            required
            isDisabled={!!link}
            options={selectOptions}
            value={selectOptions.find(val => val.value === viewOnly)}
            onChange={(data) => setViewOnly(data.value)}
          />
        </div>
        <div className="form-group mt-3">
          <label>Form Access</label>
          <Select
            placeholder="Select or Search for groups.."
            value={accessGroup}
            options={listGroup}
            isClearable={false}
            isMulti
            isSearchable
            onChange={(data) => setAccessGroup(data)}
            styles={{
              option: (base, state) => ({ ...base, paddingLeft: 40 }),
            }}
          />
          <p className="mt-2">Leave it blank to have all forms access</p>
        </div>
        {link && (
          <div className="form-group mt-3">
            <label>Invite Link (will expire in 24 hours)</label>
            <Input value={link} readOnly onChange={undefined} />
          </div>
        )}
        <div className="form-group mt-3">
          <Button submit green className="float-right" style={{ width: 200 }}>
            {!link ? 'GET LINK' : 'COPY AND CLOSE'}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default GetInviteLinkModal;
