import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Input, Select, Button, ReorderList } from 'components';
import { Edit, Remove } from 'imgs/SVG';
import Store from 'store';
import _ from 'lodash';

import UpsertFieldModal from './UpsertField.Modal';

const FORMTYPE = {
  SINGLE_LINE_TEXT: 'SINGLE_LINE_TEXT',
  MULTI_LINES_TEXT: 'MULTI_LINES_TEXT',
  NUMBER: 'NUMBER',
  URL: 'URL',
  DATE: 'DATE',
  DROPDOWN: 'DROPDOWN',
  MULTI_LINES_DROPDOWN: 'MULTI_LINES_DROPDOWN',
  UPLOAD_FILE: 'UPLOAD_FILE',
}

const typeOptions = [
  { value: FORMTYPE.SINGLE_LINE_TEXT, label: 'Single line text' },
  { value: FORMTYPE.MULTI_LINES_TEXT, label: 'Multi lines text' },
  { value: FORMTYPE.NUMBER, label: 'Number' },
  { value: FORMTYPE.URL, label: 'URL' },
  { value: FORMTYPE.DATE, label: 'Date' },
  { value: FORMTYPE.DROPDOWN, label: 'Dropdown' },
  { value: FORMTYPE.UPLOAD_FILE, label: 'Upload File' },
];

const defaultFields = [
  {
    "name": "Name",
    "isRequired": true,
    "type": "SINGLE_LINE_TEXT",
    "options": "",
  }, {
    "name": "Phone",
    "isRequired": true,
    "type": "SINGLE_LINE_TEXT",
    "options": "",
  }, {
    "name": "Email",
    "isRequired": true,
    "type": "SINGLE_LINE_TEXT",
    "options": "",
  }
];

const isAlwaysRequired = (fieldName) => ["Name", "Email", "Phone"].includes(fieldName);

const Form = ({ detail, onSaveFields, onSaveForms }) => {
  const [fields, setFields] = useState(JSON.parse(JSON.stringify(detail.fields)));
  const [forms, setForms] = useState(JSON.parse(JSON.stringify(detail.forms)));
  const [fieldFormModalData, setFieldFormModalData] = useState({
    open: false,
    fieldIndex: 3,
    formIndex: 0,
    data: {},
  });

  useEffect(() => {
    setFields(JSON.parse(JSON.stringify(detail.fields)))
  }, [detail.fields]);

  useEffect(() => {
    setForms(JSON.parse(JSON.stringify(detail.forms)))
  }, [detail.forms]);

  const [tempForm, setTempForm] = useState({
    name: '',
    password: '',
    fields: [...defaultFields],
    isEdit: true,
  });

  const [pickFieldData, setPickFieldData]: any = useState({})

  const addTempForm = () => {
    const newForm = forms.slice();
    newForm.push({ ...tempForm });
    setPickFieldData({
      ...pickFieldData,
      [newForm.length - 1]: {}
    });
    setForms(newForm);
  };

  const onCancelTempForm = (index) => {
    setForms([
      ...forms.slice(0, index),
      ...forms.slice(index + 1, forms.length),
    ]);
  };

  const saveForms = async (e) => {
    const data = forms.map((val, i) => {
      const obj = {
        ...val,
        groupId: detail.id,
      };
      return obj;
    });
    const [res, err] = await Store.Group.saveForms(data);
    if (err) {
      console.log('err', err);
      return alert("Error: " + String(err));
    }
    if (!res.success) {
      return alert("Error: " + String(res.message));
    }
    onSaveForms(forms);
    setTimeout(() => {
      setPickFieldData({});
    }, 2000);
  }

  const removeForm = async (formId) => {
    const shouldRemove = window.confirm('Are you sure to remove this form');
    if (!shouldRemove) return;
    const data = forms.filter(val => val.id !== formId);
    const [res, err] = await Store.Group.removeForms({ id: formId, groupId: detail.id });
    if (err) {
      console.log('err', err);
      return alert("Error: " + String(err));
    }
    if (!res.success) {
      return alert("Error: " + String(res.message));
    }
    onSaveForms(data);
  }

  const addNewField = (formIndex) => {
    setFieldFormModalData({
      open: true,
      formIndex: formIndex,
      fieldIndex: forms[formIndex].fields.length,
      data: {
        name: '',
        type: FORMTYPE.SINGLE_LINE_TEXT,
        options: '',
        isRequired: false,
      },
    })
  }

  const removeField = (formIndex, fieldIndex) => {
    const formsData = forms.slice();
    const thisForm = formsData[formIndex];
    thisForm.fields = [
      ...thisForm.fields.slice(0, fieldIndex),
      ...thisForm.fields.slice(fieldIndex +1, thisForm.fields.length),
    ];
    setForms(formsData);
  };

  return (
    <section className="mt-3">
      <h3 style={{ marginLeft: 30, marginBottom: 20, marginTop: 30, }}>Group Forms</h3>

      {forms.map((val, i) => (
        <div key={"form-item-" + i} className="list-item-row">
          <div className="row d-flex align-items-start">
            {!val.isEdit ? (
              <div className="col-12 col-md-9">
                <div className="form-group">
                  <label>Form Name: {val.name}</label>
                </div>
                <div className="form-group">
                  <label>Link: <Link to={val.link} target="_blank">{window.location.protocol}//{window.location.host}{val.link}</Link></label>
                </div>
              </div>
            ) : (
                <div className="col-12 col-md-9">
                  <div className="form-group">
                    <label>Form Name</label>
                    <Input
                      placeholder="Name"
                      value={val.name}
                      onChange={e => {
                        const newForms = forms.slice();
                        newForms[i].name = e.target.value;
                        setForms(newForms);
                      }}
                    />
                  </div>
                  {/* <div className="form-group">
                    <label>Password Protected (leave blank to disable)</label>
                    <Input
                      placeholder="Password"
                      value={val.password}
                      onChange={e => {
                        const newForms = forms.slice();
                        newForms[i].password = e.target.value;
                        setForms(newForms);
                      }}
                    />
                  </div> */}
                  <div className="form-group">
                    <label>Fields</label>
                    <ReorderList
                      data={val.fields}
                      onChange={(newFiels)=> {
                        const newForms = forms.slice();
                        newForms[i].fields = newFiels;
                        setForms(newForms);
                      }}
                      renderItem={(fval, fi) => (
                        <div key={"f-item-" + fi} className="list-item-row m-r-5">
                          <div className="row d-flex align-items-center">
                            <div>
                              <i className="fa fa-th" />
                            </div>
                            <div className="col-12 col-md-4 d-flex aligns-item-center">
                              <Input
                                value={fval.name}
                                onChange={e => {
                                  const newForms = forms.slice();
                                  newForms[i].fields[fi].name = e.target.value;
                                  setForms(newForms);
                                }}
                                placeholder="Name"
                                disabled={Store.User.viewOnly()}
                              />
                            </div>
                            <div className="col-12 col-md-4">
                              <Select
                                options={typeOptions}
                                placeholder="Type"
                                value={fval.type ? typeOptions.find(item => item.value === fval.type) : null}
                                onChange={data => {
                                  const newForms = forms.slice();
                                  newForms[i].fields[fi].type = !!data ? data.value : null;
                                  setForms(newForms);
                                }}
                                isDisabled={Store.User.viewOnly()}
                              />
                            </div>
                            <div className="col-12 col-md-3 d-flex align-items-center">
                              {!isAlwaysRequired(fval.name) && !Store.User.viewOnly() && (
                                <>
                                  <Edit onClick={() => setFieldFormModalData({
                                    open: true,
                                    fieldIndex: fi,
                                    formIndex: i,
                                    data: fval,
                                  })} style={{ fill: 'black', width: 25, cursor: 'pointer' }} />
                                  <Remove onClick={() => removeField(i, fi)} style={{ fill: 'black', width: 25, cursor: 'pointer' }} />
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    />
                    <div className="list-item-row p-l-15 pointer">
                      <i className="fa fa-plus" onClick={() => addNewField(i)} /> <span onClick={() => addNewField(i)}>Add new field</span>    
                    </div>
                  </div>
                </div>
              )}
            {val.isEdit ? (
              <div className="col-12 col-md-3">
                {!val.id && <Button grey onClick={() => onCancelTempForm(i)}>Cancel</Button>}
                {!!val.id && !_.isEqual(val, detail.forms[i]) && <Button grey onClick={() => {
                  const newForms = forms.slice();
                  newForms[i] = Object.assign({}, detail.forms[i]);
                  setForms(newForms);
                }}>Clear Changes</Button>}
              </div>
            ) : Store.User.viewOnly() ? null : (
              <div className="col-12 col-md-3">
                <Button blue className="mr-2 mb-2" onClick={() => {
                  const newForms = forms.slice();
                  newForms[i].isEdit = true;
                  let selectdField = {};
                  if (newForms[i].fields) {
                    newForms[i].fields.forEach((ff) => {
                      selectdField[ff.name] = {
                        checked: true,
                        isRequired: ff.isRequired,
                        options: ff.options,
                        type: ff.type,
                      }
                    });
                  }
                  setForms(newForms);
                  setPickFieldData({
                    ...pickFieldData,
                    [i]: selectdField,
                  })
                }}>Edit</Button>
                <Button grey onClick={() => removeForm(val.id)}>Remove</Button>
              </div>
            )}
          </div>
        </div>
      ))}

      {!Store.User.viewOnly() &&
      <div className="list-item-row" style={{ padding: '20px 30px' }}>
        <div className="row d-flex align-items-center">
          <div className="col-12 col-md-9 pointer"><i className="fa fa-plus" onClick={addTempForm} /> <span onClick={addTempForm}>Add new form</span></div>
          <div className="col-12 col-md-3 pointer">
            <Button blue className="m-l-10" onClick={saveForms}>Save Form</Button>
          </div>
        </div>
      </div>}
      <UpsertFieldModal
        open={fieldFormModalData.open}
        onClose={() => setFieldFormModalData({ ...fieldFormModalData, open: false })}
        fieldIndex={fieldFormModalData.fieldIndex}
        fieldData={fieldFormModalData.data}
        onSave={(newFieldData) => {
          const newForms = forms.slice();
          const { fieldIndex, formIndex } = fieldFormModalData;
          newForms[formIndex].fields[fieldIndex] = newFieldData;
          setForms(newForms);
        }}
      />
    </section>
  );
};

export default Form;
