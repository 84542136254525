import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Container, Button, Select, Input } from 'components';
import './SMSTemplate.scss';
import Store from 'store';

const DetailSMS = (props) => {
  const [detail, setDetail] : any = useState({});

  const getDetail = async () => {
    const id = props.match.params.id;
    const [res, err] = await Store.SMSTemplate.detail(id);
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    setDetail(res.data);
  };

  useEffect(() => {
    getDetail();
  }, [window.location.pathname]);

  const onSubmitForm = async e => {
    e.preventDefault();
    const { name, message, id } = detail;
    if (!name) {
      return alert("Please fill the Name field");
    }
    const [res, err] = await Store.SMSTemplate.create({ name, message, id });
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    props.history.push('/sms-templates');
  }

  const goToCreate = () => {
    props.history.push(`/create-sms-template`);
  };

  const removeSMS = async () => {
    const shouldRemove = window.confirm('Are you sure to remove this email');
    if (!shouldRemove) return;
    const [res, err] = await Store.SMSTemplate.remove(detail.id);
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    props.history.push('/sms-templates');
  };

  return (
    <Container>
      <div className="group-container">
        <section style={{ padding: 30 }}>
          {!Store.User.viewOnly() &&
          <div>
            <Button grey className="float-right" onClick={() => goToCreate()}>
              Add New
            </Button>
            <Button blue className="float-right mr-3" onClick={() => removeSMS()}>
              Remove SMS
            </Button>
            <div style={{ clear: 'both' }} />
          </div>}
          <div className="d-flex align-items-center mt-3">
            <h3 className="heading mr-4">SMS > Edit</h3>
          </div>
        </section>
        <form className="mt-3 p-l-30" onSubmit={onSubmitForm}>
          <div className="row form-group">
            <div className="col-2">
              Name:
            </div>
            <div className="col-12 col-md-6">
              <Input value={detail.name} onChange={e => setDetail({ ...detail, name: e.target.value })} />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-2">
              Message:
            </div>
            <div className="col-12 col-md-6">
              <textarea style={{ height: 100, padding: 10, width: '100%', borderRadius: 8, borderColor: '#BFBFBF' }} value={detail.message} onChange={e => setDetail({ ...detail, message: e.target.value })} />
            </div>
          </div>
          <p>Tags Available are: [name], [email], [phone]</p>
          {!Store.User.viewOnly() &&
          <div className="row form-group">
            <div className="col-12">
              <Button submit green>Save</Button>
            </div>
          </div>}
        </form>
      </div>
    </Container>
    
  );
}

export default withRouter(observer(DetailSMS));
