import React, { useState, useEffect } from 'react';
import { Modal, Button, Input, Select } from 'components';

interface Props {
  open: boolean,
  onClose(): void,
  fieldIndex: number,
  fieldData: any,
  onSave(newData: any): any,
}

const FORMTYPE = {
  SINGLE_LINE_TEXT: 'SINGLE_LINE_TEXT',
  MULTI_LINES_TEXT: 'MULTI_LINES_TEXT',
  NUMBER: 'NUMBER',
  URL: 'URL',
  DATE: 'DATE',
  DROPDOWN: 'DROPDOWN',
  MULTI_LINES_DROPDOWN: 'MULTI_LINES_DROPDOWN',
  UPLOAD_FILE: 'UPLOAD_FILE',
}

const typeOptions = [
  { value: FORMTYPE.SINGLE_LINE_TEXT, label: 'Single line text' },
  { value: FORMTYPE.MULTI_LINES_TEXT, label: 'Multi lines text' },
  { value: FORMTYPE.NUMBER, label: 'Number' },
  { value: FORMTYPE.URL, label: 'URL' },
  { value: FORMTYPE.DATE, label: 'Date' },
  { value: FORMTYPE.DROPDOWN, label: 'Dropdown' },
  { value: FORMTYPE.MULTI_LINES_DROPDOWN, label: 'Multi lines Dropdown' },
  { value: FORMTYPE.UPLOAD_FILE, label: 'Upload File' },
];

const multilinesPlaceholder =
`Enter the dropdown options
---
separated by (---)
---
`;

export default (props: Props) => {

  const [data, setData] = useState({
    name: '',
    isRequired: '',
    type: '',
    options: '',
  });

  const [height, setHeight] = useState('55vh');

  useEffect(() => {
    if (props.open) {
      console.log('props.fieldData', props.fieldData);
      setData(props.fieldData);
    }
  }, [props.open]);

  useEffect(() => {
    if (props.fieldData.type === FORMTYPE.MULTI_LINES_DROPDOWN) setHeight('80vh');
  }, [props.fieldData.type])

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <div className="list-tracking-container mt-2" style={{ height, padding: 15 }}>
        <div className="form-group">
          <label>Name</label>
          <Input
            value={data.name}
            onChange={e => {
              setData({ ...data, name: e.target.value });
            }}
            placeholder="Name"
          />
        </div>
        <div className="form-group">
          <label>Required?</label>
          <Select
            value={data.isRequired ? { value: true, label: "YES" } : { value: false, label: "NO" }}
            options={[
              { value: false, label: "NO" },
              { value: true, label: "YES" },
            ]}
            onChange={op => {
              setData({ ...data, isRequired: op.value});
            }}
            isClearable={false}
          />
        </div>
        <div className="form-group">
          <label>Type</label>
          <Select
            isClearable={false}
            options={typeOptions}
            placeholder="Type"
            value={data.type ? typeOptions.find(item => item.value === data.type) : null}
            onFocus={() => setHeight('80vh')}
            onBlur={() => data.type !== FORMTYPE.MULTI_LINES_DROPDOWN && setHeight('55vh')}
            onChange={op => {
              const newData = { ...data };
              newData.type = !!op ? op.value : null;
              setData(newData);
            }}
          />
        </div>
        {data.type === FORMTYPE.DROPDOWN && (
          <div className="form-group">
            <label>Options</label>
            <Input
              value={data.options}
              onChange={e => {
                setData({ ...data, options: e.target.value });
              }}
              placeholder="Enter the dropdown options separated by | (pipe)"
            />
          </div>
        )}
        {data.type === FORMTYPE.MULTI_LINES_DROPDOWN && (
          <div className="form-group">
            <label>Options</label>
            <textarea
              className="textarea input-control input-group"
              rows={5}
              value={data.options}
              placeholder={multilinesPlaceholder}
              onChange={e => setData({ ...data, options: e.target.value })}
            />
          </div>
        )}
        <div className="form-group">
          <Button green onClick={() => {
            props.onSave(data);
            props.onClose();
          }}>Save</Button>
        </div>
      </div>
    </Modal>
  )
};
