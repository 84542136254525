import React, { useState, useEffect } from 'react';
import Store from 'store';
import Modal from '../modal/Modal';
import Button from '../button/Button';
import Input from '../input/Input';
import Select from '../select/Select';
import './ListTrackingModal.scss';
import moment from 'moment';
import { Download } from '../../imgs/SVG';

interface Props {
  open: boolean;
  onClose(): void;
  data: any;
}

const TrackingOneUserDataModal = (props: Props) => {

  const [list, setList] = useState([]);

  const openRate = (() => {
    console.log('list', list);
    if (!list || list.length === 0) return 0;
    return ((list.filter(val => val.isOpened).length / list.length) * 100).toFixed(2);
  })();

  console.log('openRate', openRate);

  const getListTracking = async () => {
    const [res, err] = await Store.Schedule.listTracking({
      scheduleId: 0,
      contactId: props.data.id,
    });
    if (err) return alert(err);
    if (!res.success) return alert(res.message);
    setList(res.data);
  }

  useEffect(() => {
    if (props.open) {
      console.log(props.data);
      getListTracking();
    }
  }, [props.open])

  const exportExcel = () => {
    // @ts-ignore
    const XLSX = window.XLSX;
    const wb = XLSX.utils.book_new();
    const sheetName = "Tracking";
    let exportedData = list.map((val, i) => {
        return [
          val.contactName,
          val.contactEmail,
          val.contactPhone,
          val.schedule.entityName,
          moment(val.createdAt).format('YYYY-MM-DD HH:mm:ss'),
          moment(val.openedAt).year() === 1 ? '' : moment(val.openedAt).format('YYYY-MM-DD HH:mm:ss'),
        ];
    });
    exportedData.unshift(["Name", "Email", "Phone", "Schedule Name",  "Delivered At", "Oopened At"]);
    const ws = XLSX.utils.aoa_to_sheet(exportedData);
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, "tracking.xlsx");
  };

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <h3>Delivery Tracking <span style={{ marginLeft: 20, padding: '1px 3px', borderRadius:3, border: '1px solid rgba(0,0,0,0.2)' }}>
          <Download onClick={exportExcel} style={{ fill: 'black', cursor: 'pointer' }} />
        </span></h3>
      <p>Logs for {props.data.name} event created on {moment(props.data.createdAt).format('HH:mm / DD-MM-YYYY')}. The open rate for this email is {openRate}%</p>
      <div className="list-tracking-container mt-2">
        {!list || list.length === 0 ? (
          <p>No tracking found</p>
        ) : (
            <table className="table track-user">
              <tbody>
              {list.map((val, i) => {
                const isDeleted = val.schedule.entityName === '';
                return (
                  <tr key={"item-"+i}>
                    <td>{val.scheduleEntityName}{isDeleted && ' (deleted)'}</td>
                    <td>{!val.isOpened ? `Delivered At ${moment(val.createdAt).format('HH:mm:ss / DD-MM-YYYY')}` : `Opened At ${moment(val.openedAt).year() === 1 ? '' : moment(val.openedAt).format('HH:mm:ss / DD-MM-YYYY')}`}</td>
                  </tr>
                )
              })}
              </tbody>
            </table>
          )}
      </div>
    </Modal>
  );
};

export default TrackingOneUserDataModal;
