import React, { useState } from 'react';
import Store from 'store';
import Modal from '../modal/Modal';
import Button from '../button/Button';
import Input from '../input/Input';
import Select from '../select/Select';

interface Props {
  open: boolean;
  onClose(): void;
  onCreated?(): void;
}

const AddNewGroupModal = (props : Props) => {
  const [createData, setCreateData] = useState({ name: '', department: null, category: '' });

  const createGroup = async () => {
    if (!createData.department || !createData.name || !createData.category) {
      return alert('Please fill all the fields');
    }
    const [res, err] = await Store.Group.createGroup({
      category: createData.category,
      name: createData.name,
      departmentId: createData.department.value
    });
    if (err) return alert('Error: ' + String(err));
    props.onClose();
    setCreateData({ name: '', department: null, category: '' });
    !!props.onCreated && props.onCreated();
  }

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <form className="form-group">
        <h3 className="heading">Create a group</h3>
        <div className="form-group mt-3">
          <Input placeholder="Group Name" value={createData.name} onChange={e => setCreateData({ ...createData, name: e.target.value })} />
        </div>
        <div className="form-group mt-3">
          <Select
            placeholder="Department"
            options={Store.Group.departments.map(val => ({ value: val.id, label: val.name }))}
            value={createData.department}
            onChange={data => {
              setCreateData({
                ...createData,
                department: data,
              })
            }}
          />
        </div>
        <div className="form-group mt-3">
          <Input placeholder="Category" value={createData.category} onChange={e => setCreateData({ ...createData, category: e.target.value })} />
        </div>
        <div className="form-group mt-3">
          <Button green className="float-right" style={{ width: 200 }} onClick={createGroup}>
            Create
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default AddNewGroupModal;
