import React, { useState, useEffect } from 'react';
import Store from 'store';
import {
  Modal, Button, Input, 
} from 'components';

interface Props {
  open: boolean;
  onClose(): void;
  onUpdated?(): void;
  onOpenUpload(): any,
  data: any;
}

const UpdateComplianceModal = (props : Props) => {
  const [createData, setCreateData] = useState(props.data);

  useEffect(() => {
    setCreateData(props.data);
  }, [props.data]);

  const save = async () => {
    if (!createData.addressLine1 || !createData.postCode) {
      return alert('Please fill all the fields');
    }
    const [res, err] = await Store.Compliance.upsert({
      id: createData.id,
      addressLine1: createData.addressLine1,
      addressLine2: createData.addressLine2,
      postCode: createData.postCode,
      files: createData.files,
    });
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    props.onClose();
    setCreateData({
      addressLine1: '',
      addressLine2: '',
      postCode: '',
    });
    !!props.onUpdated && props.onUpdated();
  };

  const removeFile = index => {
    const newData = Object.assign({}, createData);
    newData.files = [...newData.files.slice(0, index), ...newData.files.slice(index + 1, newData.files.length)];
    setCreateData(newData);
  }

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <form className="form-group">
        <h3 className="heading">Edit Address</h3>
        <div className="form-group mt-3">
          <Input placeholder="Address line 1" value={createData.addressLine1} onChange={e => setCreateData({ ...createData, addressLine1: e.target.value })} />
        </div>
        <div className="form-group mt-3">
          <Input placeholder="Address line 2" value={createData.addressLine2} onChange={e => setCreateData({ ...createData, addressLine2: e.target.value })} />
        </div>
        <div className="form-group mt-3">
          <Input placeholder="Postal Code" value={createData.postCode} onChange={e => setCreateData({ ...createData, postCode: e.target.value })} />
        </div>
        <div className="form-group mt-3">
          <p>Files: <i className="fa fa-upload" style={{ color: '#466EA3', fontSize: 20, width: 28, cursor: 'pointer', marginLeft: 20, }} onClick={() => {
            props.onClose();
            props.onOpenUpload();
          }} /></p>
          {!!createData.files && createData.files.map((item, index) => (
            <p key={"item-" + index}><a  href={item.url} target="_blank"><i className="fa fa-file-o mr-2" />: {item.title} ({item.date})</a> <i onClick={() => removeFile(index)} className="fa fa-times ml-2 pointer" /></p>
          ))}
        </div>
        <div className="form-group mt-3">
          <Button green className="float-right" style={{ width: 200 }} onClick={save}>
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default UpdateComplianceModal;
