import React, { useState } from 'react';
import Store from 'store';
import Modal from '../modal/Modal';
import Button from '../button/Button';
import Input from '../input/Input';
import Select from '../select/Select';
import './ListTrackingModal.scss';
import moment from 'moment';
import { Download } from '../../imgs/SVG';

interface Props {
  open: boolean;
  onClose(): void;
  list: Array<any>;
}

const ListTrackingModal = (props: Props) => {

  const openRate = (() => {
    if (!props.list || props.list.length === 0) return 0;
    return ((props.list.filter(val => val.isOpened).length / props.list.length) * 100).toFixed(2);
  })();

  const exportExcel = () => {
    // @ts-ignore
    const XLSX = window.XLSX;
    const wb = XLSX.utils.book_new();
    const sheetName = "Tracking";
    let exportedData = props.list.map((val, i) => {
        return [
          val.contactName,
          val.contactEmail,
          val.contactPhone,
          moment(val.createdAt).format('YYYY-MM-DD HH:mm:ss'),
          moment(val.openedAt).year() === 1 ? '' : moment(val.openedAt).format('YYYY-MM-DD HH:mm:ss'),
        ];
    });
    exportedData.unshift(["Name", "Email", "Phone", "Delivered At", "Oopened At"]);
    const ws = XLSX.utils.aoa_to_sheet(exportedData);
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, "tracking.xlsx");
  };

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <h3>Delivery Tracking (Open Rate: {openRate}%) <Download onClick={exportExcel} style={{ fill: 'black', marginLeft: 20, cursor: 'pointer' }} /></h3>
      <div className="list-tracking-container mt-2">
        {!props.list || props.list.length === 0 ? (
          <p>No tracking found</p>
        ) : (
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">SMS</th>
                  <th scope="col">Delivered At</th>
                  <th scope="col">Opened At</th>
                </tr>
              </thead>
              <tbody>
                {props.list.map((val, i) => (
                  <tr key={"item-"+i}>
                    <td>{val.contactName}</td>
                    <td>{val.contactEmail}</td>
                    <td>{val.contactPhone}</td>
                    <td>{moment(val.createdAt).format('HH:mm:ss / DD-MM-YYYY')}</td>
                    <td>{!val.isOpened ?  '' : moment(val.openedAt).format('HH:mm:ss / DD-MM-YYYY')}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
      </div>
    </Modal>
  );
};

export default ListTrackingModal;
