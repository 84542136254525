import React from 'react';
import classnames from 'classnames';

import './Button.scss';

const Button = (props) => {
  const propsObj = {
    className: classnames(
      "ra-button",
      props.blue ? "blue" : "",
      props.grey ? "grey" : "",
      props.green ? "green" : "",
      props.small ? "small" : "",
      props.className,
    ),
    onClick: props.onClick,
    style: props.style,
  }
  if (props.submit) {
    return (
      <input
        type="submit"
        value={props.children}
        {...propsObj}
      />
    )
  }
  return (
  <div {...propsObj} >{props.children}</div>
  )
  // return (
  //   <div
  //     className={classnames(
  //       "ra-button",
  //       props.blue ? "blue" : "",
  //       props.grey ? "grey" : "",
  //       props.green ? "green" : "",
  //       props.className,
  //     )}
  //     onClick={props.onClick}
  //     style={props.style}
  //   >
  //     {props.children}
  //   </div>
  // )
};

export default Button;
