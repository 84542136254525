import React, { useState, useEffect } from 'react';
import Store from 'store';
import Modal from '../modal/Modal';
import Button from '../button/Button';
import Input from '../input/Input';
import Select from '../select/Select';

interface Props {
  open: boolean;
  onClose(): void;
  contactId: number;
}

const DuplicateContactModal = (props : Props) => {
  // const [createData, setCreateData] = useState({ name: '', department: null, category: '' });

  // const createGroup = async () => {
  //   if (!createData.department || !createData.name || !createData.category) {
  //     return alert('Please fill all the fields');
  //   }
  //   const [res, err] = await Store.Group.createGroup({
  //     category: createData.category,
  //     name: createData.name,
  //     departmentId: createData.department.value
  //   });
  //   if (err) return alert('Error: ' + String(err));
  //   props.onClose();
  //   setCreateData({ name: '', department: null, category: '' });
  //   !!props.onCreated && props.onCreated();
  // }

  const [list, setList] = useState([]);
  const [group, setGroup] : any = useState(null);

  const getListGroup = async () => {
    if (list.length !== 0) return;
    const [res, err] = await Store.Group.getAllGroups();
    if (err) return alert('Error: ' + String(err));
    console.log('res', res);
    if (!res.success) return alert('Error: ' + String(res.message));
    if (res.data) {
      let newList = [];
      res.data.forEach((val) => {
        const departmentName = val.department.name;
        const findIndex = newList.findIndex(item => item.label === departmentName);
        if (findIndex === -1) {
          newList.push({
            label: departmentName,
            options: [
              { label: val.name, value: val.id },
            ]
          })
        } else {
          newList[findIndex].options.push({ label: val.name, value: val.id });
        }
      });

      setList(newList);
    }
  }

  const duplicateContact = async () => {
    if (!group || !group.value) return alert("Please select a group");
    const [res, err] = await Store.Contact.duplicate({
      contactId: props.contactId,
      groupId: group.value,
    });
    if (err) return alert('Error: ' + String(err));
    console.log('res', res);
    if (!res.success) return alert('Error: ' + String(res.message));
    alert("Successfully duplicated");
    props.onClose();
  };
  
  useEffect(() => {
    if (props.open) {
      getListGroup();
    }
  }, [props.open]);

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <form className="form-group">
        <h3 className="heading">Duplicate contact</h3>
        <div className="form-group mt-3">
          <label>Duplicate contact to:</label>
          <Select
            placeholder="Select a group"
            options={list}
            value={group}
            onChange={data => setGroup(data)}
          />
        </div>
        <div className="form-group mt-3">
          <Button green className="float-right" style={{ width: 200 }} onClick={duplicateContact}>
            Duplicate
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default DuplicateContactModal;
