import { observable, autorun } from "mobx";
import Request from "./Request.Utils";
import StoreClass from './StoreClass.Utils';
import erria from 'erria/decorator';

class SMSTemplateStore extends StoreClass {
  constructor() {
    super();
    this.loadInitialData();
  }

  loadInitialData = async () => {
    this.makeReady();
  }

  @erria
  async list() {
    const res = await Request.get('/sms-template/list', {});
    return res.data;
  }

  @erria
  async detail(id) {
    const res = await Request.get('/sms-template/detail/' + id, {});
    return res.data;
  }

  @erria
  async create({ name, message, id }) {
    const res = await Request.post('/sms-template/create', { name, message, id });
    return res.data;
  }

  @erria
  async remove(id) {
    const res = await Request.post('/sms-template/detail/remove', { id });
    return res.data;
  }

  @erria
  async sendTest({ number, message, useTwilio }) {
    const res = await Request.post('/sms-template/send-test', { number, message, useTwilio });
    return res.data;
  }
}

export default new SMSTemplateStore();

