import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Container, Button, Select, AddNewGroupModal, Notes, EditGroupModal } from 'components';
import moment from 'moment';
import classnames from 'classnames';
import './GroupList.scss';
import Store from 'store';
import { Eye, Remove, Edit } from 'imgs/SVG';

const v = require('voca');

const GroupList = (props) => {
  const [list, setList] = useState([]);
  const [cate, setCate] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [createData, setCreateData] = useState({ name: '', department: null, category: '' });

  const [showEditModal, setShowEditModal] = useState(false);
  const [editData, setEditData] = useState({ name: '', id: 0, useTwilio: false });

  const getList = async () => {
    const department = props.match.params.name;
    const [res, err] = await Store.Group.getGroupList({ department });
    if (err) return alert('Error: ' + String(err));
    setList(res);
  };

  const getDepartmentList = async () => {
    await Store.Group.getDepartmentList();
    const department = props.match.params.name;
    console.log(Store.Group.departments);
    const find = Store.Group.departments.find(val => val.name.toLowerCase() === department);
    if (find) {
      setCreateData({
        ...createData,
        department: { value: find.id, label: find.name },
      })
    }
  };

  useEffect(() => {
    console.log(window.location.pathname);
    console.log(props.match);
    getList();
    getDepartmentList();
  }, [window.location.pathname]);

  const getListCategory = () => {
    const cates = [];
    list.forEach(val => {
      const findVal = cates.find(item => item.value === val.category);
      if (!findVal) cates.push({ value: val.category, label: val.category });
    });
    return cates;
  }

  const goToGroupDetail = ({ id, name }) => {
    props.history.push(`/group/${id}/${v.kebabCase(name.toLowerCase())}`);
  }

  const departmentName = props.match.params.name;

  const removeGroup = async (val) => {
    const shouldRemove = window.confirm(`Are you sure to remove group: ${val.name}`);
    if (!shouldRemove) return;
    const [res, err] = await Store.Group.removeGroup(val.id);
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    getList();
  }

  return (
    <Container>
      <div className="group-container">
        <section style={{ padding: 30 }}>
          <div>
            
            {!Store.User.viewOnly() &&
            <Button grey className="float-right" onClick={() => setShowCreateModal(true)}>
              Add New
            </Button>}
            {/* <Button blue className="float-right mr-3">
              View Archived
            </Button> */}
            <Notes className="float-right mr-3" slug={departmentName} />
            <div style={{ clear: 'both' }} />
          </div>
          <div className="d-flex align-items-center mt-3 roup-title-container">
            <h3 className="heading mr-4"><span className="department-name">{departmentName}</span> Groups</h3>
            {getListCategory().length >= 7 &&
            <Select
              width={250}
              placeholder="Filter group by.."
              options={getListCategory()}
              value={cate}
              onChange={data => setCate(data)}
            />}
          </div>
        </section>
        <section className="m-t-25">
          {/* {getListCategory().length <= 6 && (
            <div className="p-l-30 p-r-30">
              <div className="d-flex flex-wrap">
                {getListCategory().map((val, i) => (
                  <div
                    key={"group-"+i}
                    className={classnames("d-flex align-items-center mr-4 mb-2 category-heading-container", !!cate && val.value === cate.value ? 'active' : '' )}
                    onClick={() => setCate(val)}
                  >
                    {val.label}
                    <i className="fa fa-chevron-down ml-1" />
                  </div>
                ))}
              </div>
            </div>
          )} */}
          {list.filter(val => !cate ? true : val.category === cate.value).map((val, i) => (
            <div className="list-item-row" key={"lisst-item-"+i+val.id}>
              <div className="container-fluid">
                <div className="row d-flex align-items-start">
                  <div className="col-12 col-md-4 td-group-name">{val.name}</div>
                  <div className="col-12 col-md-3">Last Contact: <br/><span className="group-value">{moment(val.lastContactTimestamp).year() === 1 ? '' : moment(val.lastContactTimestamp).format('HH:mm / DD-MM-YYYY')}</span></div>
                  <div className="col-12 col-md-4">
                    <div className="row">
                      <div className="col-4" >Emails: <span className="group-value">{val.numberOfEmails}</span></div>
                      <div className="col-3" style={{ padding: 0 }}>SMS: <span className="group-value">{val.numberOfSMS}</span></div>
                      <div className="col-5" style={{ padding: 0 }}>Contacts: <span className="group-value">{val.numberOfContacts}</span></div>
                    </div>
                  </div>
                  <div className="col-12 col-md-1" style={{ padding: 0 }}>
                    {!Store.User.viewOnly() && (
                      <Edit onClick={() => {
                        console.log('val', val);
                        setEditData({
                          id: val.id,
                          name: val.name,
                          useTwilio: val.useTwilio,
                        })
                        setShowEditModal(true);
                      }} style={{ fill: '#466EA3', width: 22, cursor: 'pointer', marginRight: 5, }} />
                    )}
                    
                    <Eye onClick={() => {
                      console.log(val);
                      goToGroupDetail(val)
                    }} style={{ fill: '#466EA3', width: 22, cursor: 'pointer', marginRight: 5, marginBottom: 2 }} />
                    
                    {!Store.User.viewOnly() && (
                      <Remove onClick={() => {
                        removeGroup(val)
                      }} style={{ fill: '#466EA3', width: 22, cursor: 'pointer' }} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </section>
      </div>
      <AddNewGroupModal
        open={showCreateModal}
        onClose={() => setShowCreateModal(false)}
        onCreated={() => getList()}
      />
      <EditGroupModal
        id={editData.id}
        name={editData.name}
        useTwilio={editData.useTwilio}
        open={showEditModal}
        onClose={() => setShowEditModal(false)}
        onUpdated={() => getList()}
      />
    </Container>
    
  );
}

export default withRouter(observer(GroupList));
