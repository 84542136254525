import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Container, Input, Button, Select } from 'components';
import './Settings.scss';
import Store from 'store';

const Settings = (props) => {

  const [emailState, setEmailState] = useState({
    smtp_host: '',
    smtp_user: '',
    smtp_password: '',
    smtp_port: '',
    sender_email: '',
  });

  const [phoneState, setPhoneState] = useState({
    account_sid: '',
    account_token: '',
    account_phone: '',
  });

  const [contactState, setContactState] = useState({
    id: 0,
    formName: [],
    sentTo: '',
    senderEmail: '',
  });

  const [listForm, setListForm] = useState([]);

  const getData = async () => {
    const [res, err] : any = await Store.User.getPhoneAndEmailSettings();
    if (err) return alert('ERR: ' + String(err));
    console.log('res', res);
    if (!res.data) return;
    const { smtp_host, smtp_user, smtp_password, smtp_port, sender_email, account_sid, account_token, account_phone } = res.data;
    setEmailState({
      smtp_host,
      smtp_user,
      smtp_password,
      smtp_port,
      sender_email,
    });
    setPhoneState({
      account_sid,
      account_token,
      account_phone,
    });
  }

  const getListForm = async () => {
    const [res, err] = await Store.Form.getAllForm();
    if (err) return alert('ERR: ' + String(err));
    if (!res.data) return;
    const newListForm = res.data.map((val => ({ label: val.name, value: val.id })));
    setListForm(newListForm)
    const [res2, err2] = await Store.User.getContactFormSetting();
    if (err2) return alert('ERR: ' + String(err));
    if (!res2.data) return;
    let formName = [];
    if (!!res2.data.forms) {
      const ids = res2.data.forms.split(".").filter(val => !!val).map(val => +val);
      ids.forEach(id => {
        const find = newListForm.find(item => item.value === id);
        if (find) formName.push({ value: id, label: find.label });
      })
    }
    setContactState({
      id: res2.data.id,
      formName,
      sentTo: res2.data.sentTo,
      senderEmail: res2.data.senderEmail,
    });
  }

  useEffect(() => {
    getListForm();
    getData();
  }, [])

  const emailForms = [
    { label: 'SMTP Host', stateLabel: 'smtp_host', type: 'input' },
    { label: 'SMTP User', stateLabel: 'smtp_user', type: 'input' },
    { label: 'SMTP Password', stateLabel: 'smtp_password', type: 'input' },
    { label: 'SMTP Port', stateLabel: 'smtp_port', type: 'input' },
    { label: 'Sender Email', stateLabel: 'sender_email', type: 'input' },
  ];

  const phoneForms = [
    { label: 'SMS account SID', stateLabel: 'account_sid', type: 'input' },
    { label: 'SMS account token', stateLabel: 'account_token', type: 'input' },
    { label: 'SMS phone number', stateLabel: 'account_phone', type: 'input' },
  ];

  const contactForms = [
    { label: 'Form Name', stateLabel: 'formName', type: 'select-multi', options: listForm, },
    { label: 'Sent to', stateLabel: 'sentTo', type: 'input', placeholder: 'Type recepient emails, seperated by comma..' },
    { label: 'Sender Email', stateLabel: 'senderEmail', type: 'input', placeholder: '' },
  ];

  const saveSetting = async (e) => {
    e.preventDefault();
    const [res, err] = await Store.User.updatePhoneAndEmailSettings({
      ...emailState,
      ...phoneState,
    });
    if (err) return alert('ERR: ' + String(err));
    console.log('res', res);
    if (res.success) {
      alert("Settings updated successfully")
    }
  }

  const saveContactFormSetting = async e => {
    e.preventDefault();
    const { id, formName, sentTo, senderEmail } = contactState;
    if (!sentTo || formName.length === 0) return alert('Please select at least one form and type at least one email');
    let formNameString = '';
    formName.forEach(val => {
      formNameString += val.value + '.';
    });
    const [res, err] = await Store.User.updateContactFormSettings({
      id,
      sentTo,
      senderEmail,
      forms: formNameString,
    });
    if (err) return alert('ERR: ' + String(err));
    console.log('res', res);
    if (res.success) {
      alert("Settings updated successfully");
    } else {
      alert(res.message);
    }
  }

  return (
    <Container>
      <div className="group-container">
        <section style={{ padding: 30 }}>
          <div className="d-flex align-items-center mt-3">
            <h3 className="heading mr-4">Email Settings</h3>
          </div>
        </section>
        <form className="mt-3 p-l-30" onSubmit={saveSetting}>
          {emailForms.map((val, i) => (
            <div className="row form-group" key={"email-form-"+i}>
              <div className="col-2">
                {val.label}:
              </div>
              <div className="col-12 col-md-6">
                <Input value={emailState[val.stateLabel]} onChange={e => setEmailState({ ...emailState, [val.stateLabel]: e.target.value })} />
              </div>
            </div>
          ))}
          
          <div className="row form-group">
            <div className="col-12">
              <Button submit green>Save email settings</Button>
            </div>
          </div>
        </form>

        <section style={{ padding: 30 }}>
          <div className="d-flex align-items-center mt-3">
            <h3 className="heading mr-4">Phone Settings (Twilio)</h3>
          </div>
          <p>By default the system is sending sms using AQL, if you wish to use Twilio, please click edit each group and enable Twilio sms</p>
        </section>
        <form className="mt-3 p-l-30" onSubmit={saveSetting}>
          {phoneForms.map((val, i) => (
            <div className="row form-group" key={"email-form-"+i}>
              <div className="col-2">
                {val.label}:
              </div>
              <div className="col-12 col-md-6">
                <Input value={phoneState[val.stateLabel]} onChange={e => setPhoneState({ ...phoneState, [val.stateLabel]: e.target.value })} />
              </div>
            </div>
          ))}
          <div className="row form-group">
            <div className="col-12">
              <Button submit green>Save phone settings</Button>
            </div>
          </div>
        </form>

        {/* <section style={{ padding: 30 }}>
          <div className="d-flex align-items-center mt-3">
            <h3 className="heading mr-4">Contact Form</h3>
          </div>
        </section>
        <form className="mt-3 p-l-30" onSubmit={saveContactFormSetting}>
          {contactForms.map((val, i) => (
            <div className="row form-group" key={"email-form-"+i}>
              <div className="col-2">
                {val.label}:
              </div>
              <div className="col-12 col-md-6">
                {val.type === 'input' ? (
                  <Input placeholder={val.placeholder} value={contactState[val.stateLabel]} onChange={e => setContactState({ ...contactState, [val.stateLabel]: e.target.value })} />
                ) : val.type === 'select-multi' ? (
                  <Select
                    isMulti
                    options={val.options}
                    value={contactState[val.stateLabel]}
                    onChange={data => setContactState({ ...contactState, [val.stateLabel]: data })}
                  />
                ) : null}
                
              </div>
            </div>
          ))}
          <div className="row form-group">
            <div className="col-12">
              <Button submit green>Save contact settings</Button>
            </div>
          </div>
        </form> */}
      </div>
    </Container>
  );
};

export default withRouter(observer(Settings));