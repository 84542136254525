import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Store from 'store';

import './Login.scss';

import imgBgFooter from 'imgs/bg-footer.png';
import imgLogo from 'imgs/logo.jpeg';
import imgFinalLogo from 'imgs/final-logo.jpg';

const Login = (props) => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const inputHandler = (value, setValue) => ({
    value,
    onChange: e => setValue(e.target.value),
  });

  const login = async (e) => {
    e.preventDefault();
    if (!email || !password) return alert('Please fill all the fields');
    if (isLoggingIn) return;
    setIsLoggingIn(true);
    const [res, err] = await Store.User.login({ email, password });
    if (err) {
      alert("Error: " + String(err));
      setIsLoggingIn(false);
      return;
    }
    if (!res.success) {
      alert("Error: " + String(res.message));
      setIsLoggingIn(false);
      return;
    }
    if (res.success) {
      console.log('Login successfully');
      setIsLoggingIn(false);
      props.history.push('/')
    }
  }

  return (
    <div className="login-container">
      <div 
        className="login-container-inner"
        style={{
          backgroundImage: `url("${imgBgFooter}")`,
        }}
      >
        <div className="login-form-container shadow">
          <img src={imgFinalLogo} alt="" className="logo" />
          <h3 className="text-center mb-4">Enter Login details</h3>
          <form className="login-form form-group" onSubmit={login}>
            <div className="form-group">
              <input {...inputHandler(email, setEmail)} className="input-control input-group" placeholder="Email" name="email" />
            </div>
            <div className="form-group">
              <input {...inputHandler(password, setPassword)} className="input-control input-group" placeholder="Password" name="password" type="password" />
            </div>
            <div className="form-group d-flex justify-content-end">
              <u className="pointer" onClick={() => props.history.push('/forgot-password')}><b>Forgot Password</b></u>
            </div>
            <div className="form-group middle">
              <input type="submit" className="btn btn-success" value={isLoggingIn ? "LOGGING IN..." : "LOGIN"} />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
};

export default withRouter(observer(Login));
