import 'cross-fetch/polyfill';
import axios from 'axios';
import { HOST } from 'const';
const qs = require('qs');

class RequestHelper {

  _token

  setToken = (token) => {
    this._token = token;
  }

  getToken = () => {
    return this._token;
  }

  makeHeader = method => {
    let token = this.getToken();
    let headers = {};
    if(token) headers["Authorization"] = 'Bearer ' + token;
    if(method === "POST") headers["Content-Type"] = "application/json";
    return headers;
  }

  querify = (url, queryObject) => {
    let newUrl = url;
    if(!queryObject) return newUrl;
    newUrl += "?" + qs.stringify(queryObject);
    return newUrl;
  }

  get = async (URL, queryObject) => {
    const urlWithQuery = this.querify(HOST + URL, queryObject);
    const res = await axios.request({
      url: urlWithQuery,
      method: 'get',
      headers: this.makeHeader("GET"),
    });
    return {
      headers: res.headers,
      json: async () => res.data,
      text: async () => res.data,
      data: res.data,
    };
  }

  post = async (URL, bodyObject) => {
    const res = await axios.request({
      url: HOST + URL,
      method: 'post',
      headers: this.makeHeader("POST"),
      data: JSON.stringify(bodyObject)
    });
    return {
      headers: res.headers,
      json: async () => res.data,
      text: async () => res.data,
      data: res.data,
    };
  }

  upload = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    const res = await axios.post(`/api/upload`, formData, config)
    return {
      headers: res.headers,
      json: async () => res.data,
      text: async () => res.data,
      data: res.data,
    };
  }

}

export default new RequestHelper();