import User from './User.Store';
import Group from './Group.Store';
import Form from './Form.Store';
import Contact from './Contact.Store';
import EmailTemplate from './EmailTemplate.Store';
import SMSTemplate from './SMSTemplate.Store';
import Schedule from './Schedule.Store';
import Compliance from './Compliance.Store';

const Store = {
  User,
  Group,
  Form,
  Contact,
  EmailTemplate,
  SMSTemplate,
  Schedule,
  Compliance,
};

// @ts-ignore
window.store = Store;

export default Store;
