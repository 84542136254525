import React, { useState } from 'react';
import Store from 'store';
import {
  Modal, Button, Input, 
} from 'components';

interface Props {
  open: boolean;
  onClose(): void;
  onCreated?(): void;
}

const AddNewComplianceModal = (props : Props) => {
  const [createData, setCreateData] = useState({
    addressLine1: '',
    addressLine2: '',
    postCode: '',
  });

  const createGroup = async () => {
    if (!createData.addressLine1 || !createData.postCode) {
      return alert('Please fill all the fields');
    }
    const [res, err] = await Store.Compliance.upsert({
      addressLine1: createData.addressLine1,
      addressLine2: createData.addressLine2,
      postCode: createData.postCode,
      files: [],
    });
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    props.onClose();
    setCreateData({
      addressLine1: '',
      addressLine2: '',
      postCode: '',
    });
    !!props.onCreated && props.onCreated();
  }

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <form className="form-group">
        <h3 className="heading">Create new</h3>
        <div className="form-group mt-3">
          <Input placeholder="Address line 1" value={createData.addressLine1} onChange={e => setCreateData({ ...createData, addressLine1: e.target.value })} />
        </div>
        <div className="form-group mt-3">
          <Input placeholder="Address line 2" value={createData.addressLine2} onChange={e => setCreateData({ ...createData, addressLine2: e.target.value })} />
        </div>
        <div className="form-group mt-3">
          <Input placeholder="Postal Code" value={createData.postCode} onChange={e => setCreateData({ ...createData, postCode: e.target.value })} />
        </div>
        <div className="form-group mt-3">
          <Button green className="float-right" style={{ width: 200 }} onClick={createGroup}>
            Create
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default AddNewComplianceModal;
