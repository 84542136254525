import React, { useState, useEffect } from 'react';
import Store from 'store';
import Modal from '../modal/Modal';
import Button from '../button/Button';
import './EditContactModal.scss';

import FormElement from '../form-element/FormElement';

interface Props {
  open: boolean;
  onClose(): void;
  onAdd?(data : any): void;
  detail: any;
}

const AddEmailNotiModal = (props: Props) => {

  const [email, setEmail] : any = useState('');

  useEffect(() => {
    if (!props.open) {
      setEmail('');
    }
  }, [props.open]);

  const onSubmit = async () => {
    if (!email) return alert('Please fill all the information');
    const [res, err] = await Store.Group.addEmailNoti({ email, groupId: props.detail.id });
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    props.onAdd(res.data);
    props.onClose();
  };

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <h3>Add Email</h3>
      <div className="list-tracking-container mt-2">
        <FormElement
          name={"Email"}
          isRequired
          type="SINGLE_LINE_TEXT"
          value={email}
          onChange={setEmail}
          options={undefined}
        />
        <div className="form-group">
          <Button green onClick={onSubmit}>Submit</Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddEmailNotiModal;
