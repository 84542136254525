import { observable, autorun } from "mobx";
import Request from "./Request.Utils";
import StoreClass from './StoreClass.Utils';
import erria from 'erria/decorator';

class GroupsStore extends StoreClass {
  constructor() {
    super();
    this.loadInitialData();
  }

  @observable departments = [];

  loadInitialData = async () => {
    // let userData = await this.persist.sync("user_data", "object");
    // if (userData) {
    //   this.userData = userData;
    // }

    // autorun(()=>{
    //   this.persist.save(this.userData, "user_data");
    // });
    this.makeReady();
  }

  @erria
  async getGroupList(obj) {
    const { department, category } = obj;
    const res = await Request.post('/departments/groups/list', { department, category });
    console.log('res.data', res.data)
    return res.data.data;
  }

  @erria
  async getAllGroups() {
    const res = await Request.get('/departments/groups/list-all', {});
    return res.data;
  }

  @erria
  async getDepartmentList() {
    if (this.departments.length > 0 ) return this.departments;
    const res = await Request.get('/departments', {});
    if (res.data.success) {
      this.departments = res.data.data;
    }
    return res.data.data;
  }

  @erria
  async createGroup({ category, name, departmentId }) {
    const res = await Request.post('/departments/groups/create', { category, name, departmentId });
    return res.data.data;
  }

  @erria
  async editGroup({ id, name, useTwilio }) {
    const res = await Request.post('/departments/groups/edit', { id, name, useTwilio });
    return res.data.data;
  }

  @erria
  async duplicateGroup(id) {
    const res = await Request.post('/departments/groups/duplicate', { id });
    return res.data;
  }

  @erria
  async groupDetail(id) {
    const res = await Request.get('/departments/group/detail/' + id, {});
    if (res.data.data && res.data.data.contact) {
      res.data.data.contact = res.data.data.contact.map(val => {
        const fields = !val.fields ? [] : JSON.parse(val.fields);
        val.fields = fields;
        return val;
      }).sort((a,b) => a.createdAt > b.createdAt ? -1 : 1);
    }
    if (res.data.data && res.data.data.forms) {
      res.data.data.forms = res.data.data.forms.map(val => {
        const fields = !val.fields ? [] : JSON.parse(val.fields);
        val.fields = fields;
        return val;
      });
    }
    return res.data;
  }

  @erria
  async saveFields(data) {
    const res = await Request.post('/departments/groups/fields/create', { data });
    return res.data;
  }

  @erria
  async saveForms(data) {
    const res = await Request.post('/departments/groups/forms/create', { data });
    return res.data;
  }

  @erria
  async removeForms({ groupId, id }) {
    const res = await Request.post('/departments/groups/forms/remove', { groupId, id });
    return res.data;
  }

  @erria
  async removeGroup(id) {
    const res = await Request.post(`/departments/group/detail/${id}/remove`, {});
    return res.data;
  }

  @erria
  async listNote(slug) {
    const res = await Request.get(`/notes/list/${slug}`, {});
    if (res.data.data)
      res.data.data = res.data.data.map(val => ({ ...val, imgs: JSON.parse(val.imgs) }));
    return res.data;
  }

  @erria
  async upsertNote({ id, departmentGroupSlug, content, imgs }) {
    const res = await Request.post(`/notes`, { id, departmentGroupSlug, content, imgs: JSON.stringify(imgs) });
    return res.data;
  }

  @erria
  async deleteNote(id) {
    const res = await Request.post(`/notes/remove`, { id });
    return res.data;
  }

  @erria
  async listEmailNoti(groupId) {
    const res = await Request.get(`/email-noti-group/list/${groupId}`, {});
    return res.data;
  }

  @erria
  async addEmailNoti({ groupId, email }) {
    const res = await Request.post(`/email-noti-group`, { groupId, email });
    return res.data;
  }

  @erria
  async deleteEmailNoti(id) {
    const res = await Request.post(`/email-noti-group/remove`, { id });
    return res.data;
  }
}

export default new GroupsStore();

