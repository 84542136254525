import { observable, autorun } from "mobx";
import Request from "./Request.Utils";
import StoreClass from './StoreClass.Utils';
import erria from 'erria/decorator';

class EmailTemplateStore extends StoreClass {
  constructor() {
    super();
    this.loadInitialData();
  }

  loadInitialData = async () => {
    this.makeReady();
  }

  @erria
  async list() {
    const res = await Request.get('/email-template/list', {});
    return res.data;
  }

  @erria
  async detail(id) {
    const res = await Request.get('/email-template/detail/' + id, {});
    return res.data;
  }

  @erria
  async create({ name, subject, content, id, attachments }) {
    const res = await Request.post('/email-template/create', { name, subject, content, defaultSent: true, id, attachments });
    return res.data;
  }

  @erria
  async remove(id) {
    const res = await Request.post('/email-template/detail/remove', { id });
    return res.data;
  }
}

export default new EmailTemplateStore();

