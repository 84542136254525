import { observable, autorun } from "mobx";
import Request from "./Request.Utils";
import StoreClass from './StoreClass.Utils';
import erria from 'erria/decorator';

class FormStore extends StoreClass {
  constructor() {
    super();
    this.loadInitialData();
  }

  loadInitialData = async () => {
    this.makeReady();
  }

  @erria
  async getFormDetail(link) {
    const res = await Request.post('/form/detail', { link });
    if (res.data.data && res.data.data.fields) {
      res.data.data.fields = JSON.parse(res.data.data.fields);
    }
    return res.data;
  }

  @erria
  async submitPassworFormDetail({ link, password }) {
    const res = await Request.post('/form/detail/enter-password', { link, password });
    if (res.data.data && res.data.data.fields) {
      res.data.data.fields = JSON.parse(res.data.data.fields);
    }
    return res.data;
  }

  @erria
  async submitForm({ name, phone, email, fields, from }) {
    const res = await Request.post('/form/submit', { name, phone, email, fields, from });
    return res.data;
  }

  @erria
  async getAllForm() {
    const res = await Request.get('/form/all', {});
    return res.data;
  }
}

export default new FormStore();

