import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import classnames from 'classnames';
import { slide as MenuBurger } from 'react-burger-menu';
import * as Icon from 'imgs/SVG';

import './Sidebar.scss';

import imgLogo from 'imgs/logo.jpeg';
import imgLogoFinal from 'imgs/final-logo.jpg';

const Sidebar = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const menu = [
    {
      title: 'Applications',
      path: '/departments/applications',
      icon: <Icon.Manager style={{ fill: 'white', width: 24, height: 24, marginRight: 15, }} />,
      isActive: false,
    },
    {
      title: 'Tenant',
      path: '/departments/tenant',
      icon: <Icon.User style={{ fill: 'white', width: 24, height: 24, marginRight: 15, }} />,
      isActive: false,
    },
    {
      title: 'Repairs',
      path: '/departments/repairs',
      icon: <Icon.House style={{ fill: 'white', width: 24, height: 24, marginRight: 15, }} />,
      isActive: false,
    },
    {
      title: 'Compliance',
      path: '/compliance',
      icon: <Icon.Compliance style={{ fill: 'white', width: 24, height: 24, marginRight: 15, }} />,
      isActive: false,
    },
    {
      title: 'Email Templates',
      path: '/email-templates',
      icon: <Icon.Envelope style={{ fill: 'white', width: 24, height: 24, marginRight: 15, }} />,
      isActive: false,
    },
    {
      title: 'SMS Templates',
      path: '/sms-templates',
      icon: <Icon.ChatBubbles style={{ fill: 'white', width: 24, height: 24, marginRight: 15, }} />,
      isActive: false,
    },
    {
      title: 'User Admin',
      path: '/user-admins',
      icon: <Icon.UserCheck style={{ fill: 'white', width: 24, height: 24, marginRight: 15, }} />,
      isActive: false,
    },
    {
      title: 'Settings',
      path: '/settings',
      icon: <Icon.Settings style={{ fill: 'white', width: 24, height: 24, marginRight: 15, }} />,
      isActive: false,
    },
    {
      title: 'Logout',
      onClick: () => {
        localStorage.clear();
        window.location.reload();
      },
      icon: <Icon.ExitApp style={{ fill: 'white', width: 24, height: 24, marginRight: 15, }} />,
      isActive: false,
    },
  ];

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateWidth);
  }, []);

  // will unmount
  useEffect(() => {
    return () => {
      window.removeEventListener('resize', updateWidth);
    }
  }, []);

  if (width < 775) {
    return (
      <>
        <div className="sidebar-container-dummy-burger print-none">

        </div>
        <MenuBurger right pageWrapId={'page-wrap'} outerContainerId={'outer-container'} styles={burgerstyles}>
          <div className="sidebar-container-slide-out h-100">
            <img src={imgLogoFinal} alt="" className="logo-sidebar" />
            <div className="menu-list">
              {menu.map((val, i) => (
                <div className="d-flex align-items-center menu-item" key={"menu-item-"+i}>
                  {React.cloneElement(val.icon, { style: { fill: '#5079AD', width: 24, height: 24, marginRight: 15, } }, null)}
                  {val.onClick ? (
                    <span onClick={val.onClick}>{val.title}</span>
                  ) : (
                    <Link to={val.path}>{val.title}</Link>
                  )}
                </div>
              ))}
            </div>
          </div>
        </MenuBurger>
      </>
    );
  }

  return (
    <div className="sidebar-container print-none">
      <img src={imgLogoFinal} alt="" className="logo-sidebar" />
      <div className="menu-list">
        {menu.map((val, i) => (
          <div
            className={classnames("d-flex align-items-center menu-item", window.location.pathname === val.path ? 'active' : '')}
            key={"menu-item-"+i}
          >
            {val.icon}
            {val.onClick ? (
              <span onClick={val.onClick}>{val.title}</span>
            ) : (
              <Link to={val.path}>{val.title}</Link>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const burgerstyles = {
  bmBurgerButton: {
    position: 'absolute',
    width: '36px',
    height: '30px',
    right: '20px',
    top: '20px'
  },
  bmBurgerBars: {
    background: 'white'
  },
  bmBurgerBarsHover: {
    // background: '#a90000'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: '#5079AD'
  },
  bmMenuWrap: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
  },
  bmMenu: {
    background: 'white',
    padding: '2.5em 1.5em 0',
    // fontSize: '1.15em',
    overflow: 'hidden',
    border: 'none',
    outline: 'none',
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: 'black',
    padding: '0.8em'
  },
  bmItem: {
    display: 'inline-block'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
  }
};

export default withRouter(Sidebar);
