import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Container, Button, Select, Input } from 'components';
import './SMSTemplate.scss';
import Store from 'store';

const CreateSMS = (props) => {
  const [createData, setCreateData] : any = useState({});

  const onSubmitForm = async e => {
    e.preventDefault();
    const { name, message } = createData;
    if (!name) {
      return alert("Please fill the Name field");
    }
    const [res, err] = await Store.SMSTemplate.create({ name, message, id: 0 });
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    props.history.push('/sms-templates');
  }

  return (
    <Container>
      <div className="group-container">
        <section style={{ padding: 30 }}>
          <div className="d-flex align-items-center mt-3">
            <h3 className="heading mr-4">SMS > Create</h3>
          </div>
        </section>
        <form className="mt-3 p-l-30" onSubmit={onSubmitForm}>
          <div className="row form-group">
            <div className="col-2">
              Name:
            </div>
            <div className="col-12 col-md-6">
              <Input value={createData.name} onChange={e => setCreateData({ ...createData, name: e.target.value })} />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-2">
              Message:
            </div>
            <div className="col-12 col-md-6">
              <textarea style={{ height: 100, padding: 10, width: '100%', borderRadius: 8, borderColor: '#BFBFBF' }} value={createData.message} onChange={e => setCreateData({ ...createData, message: e.target.value })} />
            </div>
          </div>
          <p>Tags Available are: [name], [email], [phone]</p>
          <div className="row form-group">
            <div className="col-12">
              <Button submit green>Save</Button>
            </div>
          </div>
        </form>
      </div>
    </Container>
    
  );
}

export default withRouter(observer(CreateSMS));
