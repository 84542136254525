import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { AddNewScheduleModal, ListTrackingModal, SendEmailNowModal, SendSMSNowModal } from 'components';
import Store from 'store';
import { Remove, Eye } from 'imgs/SVG'

import './Schedule.scss';
import { Button } from 'components';
import moment from 'moment';

const Schedule = ({ detail, onChangeSchedules }) => {

  const [emails, setEmails] = useState(detail.schedules.filter(val => val.entityType === 'email'));
  const [smses, setSmses] = useState(detail.schedules.filter(val => val.entityType === 'sms'));

  const [openModal, setOpenModal] = useState(false);
  const [openSendEmailModal, setOpenSendEmailModal] = useState(false);
  const [openSendSMSModal, setOpenSendSMSModal] = useState(false);
  const [modalType, setModalType] = useState('email');

  const [openTrackingModal, setOpenTrackingModal] = useState(false);
  const [listTracking, setListTracking] = useState([]);

  const [templateEmails, setTemplateEmails] = useState([]);
  const [templateSMSes, setTemplateSMSes] = useState([]);
  
  useEffect(() => {
    setEmails(detail.schedules.filter(val => val.entityType === 'email'))
    setSmses(detail.schedules.filter(val => val.entityType === 'sms'))
  }, [detail.schedules]);

  const getListSMS = async () => {
    const [res, err] = await Store.SMSTemplate.list();
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    setTemplateSMSes(res.data);
  };

  const getListEmail = async () => {
    const [res, err] = await Store.EmailTemplate.list();
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    setTemplateEmails(res.data);
  };

  useEffect(() => {
    getListSMS();
    getListEmail();
  }, []);

  const openScheduleModal = (type) => {
    setModalType(type);
    setOpenModal(true);
  }

  const viewTracking = async (scheduleId) => {
    const [res, err] = await Store.Schedule.listTracking({ scheduleId, contactId: undefined });
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    console.log(res);
    setListTracking(res.data);
    setOpenTrackingModal(true);
  }

  const getScheduleMessage = ({ sendAtExactDate, sendAt, repeat, repeatWhen, repeatAt, autoIntervalDays, autoIntervalHours, autoIntervalMins, contactType, id, triggerType, createdAt }) => {
    let subText;
    switch (contactType) {
      case 'normal': subText = null; break;
      case 'duplicated': subText = <p style={{ fontWeight: 'normal', marginBottom: 0 }}>Send to Duplicate Contacts</p>; break;
      case 'processed': subText = <p style={{ fontWeight: 'normal', marginBottom: 0 }}>Send to Processed Contacts</p>; break;
      default: subText = null;
    }
    const typeText = triggerType === 'Newsletter' ? 'All Contacts' : triggerType;
    const eyeIcon = (
      <Eye style={{ fill: '#466EA3', marginLeft: 10, cursor: 'pointer', width: 20, transform: 'translateY(5px)', }} onClick={() => viewTracking(id)} />
    );
    if (sendAtExactDate && moment(sendAtExactDate).isAfter(moment('01/01/2000'))) {
      return <>{subText}<p>{typeText}: Scheduled At: {moment(sendAtExactDate).format('DD/MM/YYYY HH:mm:ss ZZ')} {eyeIcon}</p></>;
    }
    if (repeat && repeatWhen && repeatAt) {
      return <>{subText}<p>{typeText}: Scheduled: {repeat} at {repeatAt} {repeatWhen} {eyeIcon}</p></>;
    }
    if (!autoIntervalDays && !autoIntervalHours && !autoIntervalMins) {
      return <>{subText}<p>{typeText}: Scheduled: {sendAt} hours {triggerType === 'Form Response' ? 'after adding contact' : 'from schedule created'} {eyeIcon}</p></>;
    }
    return <>{subText}<p>{typeText}: Scheduled: {autoIntervalDays} days {autoIntervalHours} hours {autoIntervalMins} mins {triggerType === 'Form Response' ? 'after adding contact' : 'from schedule created'} {eyeIcon}</p></>;
  };

  const removeSchedule = async (id) => {
    const [res, err] = await Store.Schedule.remove(id);
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    !!onChangeSchedules && onChangeSchedules();
  }

  return (
    <section className="mt-3">
      <div className="list-item-row">
        <div>
          <h3>Email Schedule</h3>
          {emails.map((val, i) => (
            <div key={"e-"+i+val.id} className="mt-2">
              <h4>{val.entityName.toUpperCase()} {!Store.User.viewOnly() && <Remove onClick={() => removeSchedule(val.id)} style={{ fill: 'black', marginLeft: 10, transform: 'translateY(5px)', cursor: 'pointer', }} />}</h4>
              {getScheduleMessage(val)}
            </div>
          ))}
          {!Store.User.viewOnly() &&
          <div className="form-group">
            <Button green onClick={() => openScheduleModal('email')}>Add Email</Button>
            <Button className="ml-2" green onClick={() => setOpenSendEmailModal(true)}>Send Email Now</Button>
          </div>}
        </div>
        
      </div>
      <div className="list-item-row">
        <div>
          <h3>SMS Schedule</h3>
          {smses.map((val, i) => (
            <div key={"e-"+i+val.id} className="mt-2">
              <h4>{val.entityName.toUpperCase()} {!Store.User.viewOnly() && <Remove onClick={() => removeSchedule(val.id)} style={{ fill: 'black', marginLeft: 10, transform: 'translateY(5px)', cursor: 'pointer', }} />}</h4>
              {getScheduleMessage(val)}
            </div>
          ))}
          {!Store.User.viewOnly() &&
          <div className="form-group">
            <Button green onClick={() => openScheduleModal('sms')}>Add SMS</Button>
            <Button className="ml-2" green onClick={() => setOpenSendSMSModal(true)}>Send SMS Now</Button>
          </div>}
        </div>
      </div>
      <AddNewScheduleModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        type={modalType}
        list={modalType === 'email' ? templateEmails : templateSMSes}
        groupId={detail.id}
        onCreated={onChangeSchedules}
      />
      <ListTrackingModal
        open={openTrackingModal}
        onClose={() => setOpenTrackingModal(false)}
        list={listTracking}
      />
      <SendEmailNowModal
        open={openSendEmailModal}
        onClose={() => setOpenSendEmailModal(false)}
        groupId={detail.id}
      />
      <SendSMSNowModal
        open={openSendSMSModal}
        onClose={() => setOpenSendSMSModal(false)}
        groupId={detail.id}
      />
    </section>
  );
};

export default withRouter(Schedule);
