import React, { useEffect, useState } from 'react';
import ModalResponsive from 'react-responsive-modal';
import classnames from 'classnames';

import './Modal.scss';

interface Props {
  [key: string]: any;
}

export default (props : Props) => {
  return (
    <ModalResponsive
      open={Boolean(props.open)}
      onClose={props.onClose}
      center
      styles={{
        modal: {
          minWidth: 300,
          maxWidth: '100vw',
          padding: 15,
          // background: '#ECECEC',
          background: '#fff',
          borderRadius: 4,
        },
        ...props.styles,
      }}
    >
      <div className={classnames("ra-modal-container", props.containerClassName)} style={props.containerStyle}>
        {props.children}
      </div>
    </ModalResponsive>
  );
}