import { observable, autorun } from "mobx";
import Request from "./Request.Utils";
import StoreClass from './StoreClass.Utils';
import erria from 'erria/decorator';

class ScheduleStore extends StoreClass {
  constructor() {
    super();
    this.loadInitialData();
  }

  loadInitialData = async () => {
    this.makeReady();
  }

  @erria
  async add(obj) {
    const res = await Request.post('/schedules/add', obj);
    return res.data;
  }

  @erria
  async remove(id) {
    const res = await Request.post('/schedules/remove', { id });
    return res.data;
  }

  @erria
  async listTracking({ scheduleId, contactId }) {
    const res = await Request.post('/tracking/getList', { scheduleId, contactId });
    return res.data;
  }

  @erria
  async sendEmailNow({ groupId, subject, content }) {
    const res = await Request.post('/schedules/send-email-now', { groupId, subject, content });
    return res.data;
  }

  @erria
  async sendSMSNow({ groupId, content, useTwilio }) {
    const res = await Request.post('/schedules/send-sms-now', { groupId, content, useTwilio });
    return res.data;
  }
}

export default new ScheduleStore();

