import React, { useState, useEffect, useRef } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Container, Button, Select, Input } from 'components';
import './EmailTemplate.scss';
import Store from 'store';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const DetailEmail = (props) => {
  const [detail, setDetail] : any = useState({});
  const [uploadeds, setUploadeds] = useState([]);

  const getDetail = async () => {
    const id = props.match.params.id;
    const [res, err] = await Store.EmailTemplate.detail(id);
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    setUploadeds(res.data.attachments == "" ? [] : JSON.parse(res.data.attachments))
    setDetail(res.data);
  };

  useEffect(() => {
    getDetail();
  }, [window.location.pathname]);

  const onSubmitForm = async e => {
    e.preventDefault();
    const { name, subject, content, id } = detail;
    console.log({ name, subject, content, id })
    if (!name || !subject || !content) {
      return alert("Please fill all fields");
    }
    const attachments = JSON.stringify(uploadeds);
    const [res, err] = await Store.EmailTemplate.create({ name, subject, content, id, attachments });
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    props.history.push('/email-templates');
  }

  const refInput = useRef(null);

  const removeAttachment = (val, i) => {
    setUploadeds([
      ...uploadeds.slice(0, i),
      ...uploadeds.slice(i+1, uploadeds.length),
    ]);
  }

  const onUploadChange = async (e) => {
    if (e.target.files.length === 0) return;
    const [res, err] = await Store.User.upload(e.target.files[0]);
    if (err) return alert('ERR: ' + String(err));
    if (res.url) {
      setUploadeds([
        ...uploadeds,
        { fileName: res.fileName, url: res.url },
      ]);
    }
  };

  const goToCreate = () => {
    props.history.push(`/create-email-template`);
  };

  const removeEmail = async () => {
    const shouldRemove = window.confirm('Are you sure to remove this email');
    if (!shouldRemove) return;
    const [res, err] = await Store.EmailTemplate.remove(detail.id);
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    props.history.push('/email-templates');
  }

  return (
    <Container>
      <div className="group-container">
        <section style={{ padding: 30 }}>
          {!Store.User.viewOnly() &&
          <div>
            <Button grey className="float-right" onClick={() => goToCreate()}>
              Add New
            </Button>
            <Button blue className="float-right mr-3" onClick={() => removeEmail()}>
              Remove Email
            </Button>
            <div style={{ clear: 'both' }} />
          </div>}
          <div className="d-flex align-items-center mt-3">
            <h3 className="heading mr-4">Email > Edit</h3>
          </div>
        </section>
        <form className="mt-3 p-l-30" onSubmit={onSubmitForm}>
          <div className="row form-group">
            <div className="col-2">
              Name:
            </div>
            <div className="col-12 col-md-6">
              <Input value={detail.name} onChange={e => setDetail({ ...detail, name: e.target.value })} />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-2">
              Subject:
            </div>
            <div className="col-12 col-md-6">
              <Input value={detail.subject} onChange={e => setDetail({ ...detail, subject: e.target.value })} />
            </div>
          </div>
          <div className="row form-group" style={{ height: 300 }}>
            <div className="col-2">
              Content:
            </div>
            <div className="col-12 col-md-6">
              {/* <textarea style={{ height: 100, width: '100%', borderRadius: 8, borderColor: '#BFBFBF' }} value={detail.content} onChange={e => setDetail({ ...detail, content: e.target.value })} /> */}
              <ReactQuill
                theme="snow"
                value={detail.content || ''}
                onChange={text => {
                  if (!detail.content) return;
                  setDetail({ ...detail, content: text })
                }}
                modules={{
                  toolbar: {
                    container: [
                      [{ header: [1, 2, false] }],
                      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                      ['bold', 'italic', 'underline'],
                      [{ 'align': [] }],
                      ['link'],
                      ['clean']
                    ],
                  }
                }}
                style={{
                  height: 200,
                }}
              />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-2">
              Attachments: (max 4 files)
            </div>
            <div className="col-10">
              <div>
                {uploadeds.length < 4 &&
                <Button blue small onClick={() => refInput.current.click()}>
                  <i className="fa fa-upload mr-3" aria-hidden="true"></i>
                  Upload
                </Button>}
                <input ref={refInput} type="file" className="d-none" onChange={onUploadChange} />
              </div>
              {uploadeds.length > 0 && (
                <div className="mt-2 d-flex" style={{ flexWrap: 'wrap' }}>
                  {uploadeds.map((u, i) => (
                    <Button grey small onClick={() => removeAttachment(u, i)} className="mr-2 mb-2">
                      <i className="fa fa-times mr-1" aria-hidden="true"></i>
                      {u.fileName}
                    </Button>
                  ))}
                  
                </div>
              )}
            </div>
            
          </div>
          <p>Tags Available are: [name], [email], [phone]</p>
          {!Store.User.viewOnly() &&
          <div className="row form-group">
            <div className="col-12">
              <Button submit green>Save</Button>
            </div>
          </div>}
        </form>
      </div>
    </Container>
    
  );
}

export default withRouter(observer(DetailEmail));
