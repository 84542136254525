import { observable, autorun } from "mobx";
import Request from "./Request.Utils";
import StoreClass from './StoreClass.Utils';
import erria from 'erria/decorator';

class GroupsStore extends StoreClass {
  constructor() {
    super();
    this.loadInitialData();
  }

  loadInitialData = async () => {
    this.makeReady();
  }

  @erria
  async listAll() {
    const res = await Request.get(`/compliances`, {});
    if (res.data.data) {
      res.data.data = res.data.data.map(val => !!val.files ? ({ ...val, files: JSON.parse(val.files) }) : val);
    }
    return res.data;
  }

  @erria
  async remove(id) {
    const res = await Request.post(`/compliances/remove`, { id });
    return res.data;
  }

  @erria
  async upsert(data) {
    if (data.files) {
      data.files = JSON.stringify(data.files);
    }
    const res = await Request.post(`/compliances`, data);
    return res.data;
  }

  @erria
  async search(keyword) {
    const res = await Request.post(`/compliances/search`, { keyword });
    return res.data;
  }
}

export default new GroupsStore();

