import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Store from 'store';

import './Login.scss';

import imgBgFooter from 'imgs/bg-footer.png';
import imgLogo from 'imgs/logo.jpeg';
import imgFinalLogo from 'imgs/final-logo.jpg';

const PAGE = {
  REQUEST: 'REQUEST',
  RECOVER: 'RECOVER'
}

const ForgotPassword = (props) => {

  const [page, setPage] = useState(PAGE.REQUEST);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const inputHandler = (value, setValue) => ({
    value,
    onChange: e => setValue(e.target.value),
  });

  const requestPassword = async (e) => {
    e.preventDefault();
    if (!email) return alert('Please fill all the fields');
    if (isLoading) return;
    setIsLoading(true);
    const [res, err] = await Store.User.requestCodeRecoverPassword({ email });
    if (err) {
      alert("Error: " + String(err));
      setIsLoading(false);
      return;
    }
    if (!res.success) {
      alert("Error: " + String(res.message));
      setIsLoading(false);
      return;
    }
    if (res.success) {
      setIsLoading(false);
      setPage(PAGE.RECOVER);
    }
  }

  const recoverPassword = async (e) => {
    e.preventDefault();
    if (!email || !password || !code) return alert('Please fill all the fields');
    if (isLoading) return;
    setIsLoading(true);
    const [res, err] = await Store.User.recoverPassword({ email, password, code });
    if (err) {
      alert("Error: " + String(err));
      setIsLoading(false);
      return;
    }
    if (!res.success) {
      alert("Error: " + String(res.message));
      setIsLoading(false);
      return;
    }
    if (res.success) {
      setIsLoading(false);
      props.history.push('/login');
    }
  }

  return (
    <div
      className="login-container"
      style={{
        backgroundImage: `url("${imgBgFooter}")`,
      }}
    >
      <div className="login-container-inner">
        {page === PAGE.REQUEST ? (
          <div className="login-form-container shadow">
            <img src={imgFinalLogo} alt="" className="logo" />
            <form className="login-form form-group" onSubmit={requestPassword}>
              <div className="form-group m-t-30">
                <input {...inputHandler(email, setEmail)} className="input-control input-group" placeholder="Email" name="email" />
              </div>
              <div className="form-group middle">
                <input type="submit" className="btn btn-success" value={isLoading ? "REQUESTING..." : "REQUEST"} />
              </div>
            </form>
          </div>
        ) : (
          <div className="login-form-container shadow">
            <img src={imgFinalLogo} alt="" className="logo" />
            <form className="login-form form-group" onSubmit={recoverPassword}>
              <p>An Email with Verification Code was sent. Please check your mailbox and continue</p>
              <div className="form-group m-t-30">
                <input {...inputHandler(email, setEmail)} className="input-control input-group" placeholder="Email" name="email" />
              </div>
              <div className="form-group m-t-30">
                <input {...inputHandler(password, setPassword)} className="input-control input-group" placeholder="New password" name="password" type="password" />
              </div>
              <div className="form-group m-t-30">
                <input {...inputHandler(code, setCode)} className="input-control input-group" placeholder="Code from email" name="code" />
              </div>
              <div className="form-group middle">
                <input type="submit" className="btn btn-success" value={isLoading ? "SUBMITTING..." : "SUBMIT"} />
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  )
};

export default withRouter(observer(ForgotPassword));
