import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import Store from 'store';
import moment from 'moment';
import './Invite.scss';
import { Input, FormElement, Button } from 'components';

import imgBgFooter from 'imgs/bg-footer.png';
import imgFinalLogo from 'imgs/final-logo.jpg';

const InviteScreen = (props) => {
  console.log('InviteScreen');
  const [createData, setCreateData] : any = useState({});
  const [detail, setDetail]: any = useState({});

  const getInviteDetail = async () => {
    const token = props.match.params.token;
    const [res, err] = await Store.User.getInviteLinkDetail(token);
    if (err) {
      return alert("ERR: " + String(err))
    }
    if (!res.success) {
      return alert("ERR: " + String(res.message))
    }
    if (res.data) {
      setDetail(res.data);
    }
  }

  useEffect(() => {
    getInviteDetail();
  }, [window.location.pathname]);

  const onSubmitForm = async e => {
    e.preventDefault();
    const { firstName, lastName, password, email } = createData;
    if (!firstName || !lastName || !password || !email) {
      return alert("Please fill all fields");
    }
    const token = props.match.params.token;
    const [res, err] = await Store.User.createAdminWithToken({ firstName, lastName, password, email, token });
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    alert('Your account has been created. You can login with your login detail now.');
    props.history.push('/login');
  }

  const isExpired = !detail.createdAt ? false : moment().diff(detail.createdAt, 'days') > 1

  if (isExpired) {
    return (
      <div className="ra-form-container">
        <div className="row ra-form-container-row">
          <div className="col-12 col-md-6 ra-form-container-inner">
            <h3 className="text-center">This invite link is expired!</h3>
          </div>
        </div>
      </div>
    )
  }

  return (
    <form className="ra-form-container" onSubmit={onSubmitForm} style={{
      backgroundImage: `url("${imgBgFooter}")`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom',
    }}>
      <div className="invite-logo-container">
        <img src={imgFinalLogo} alt="" />
      </div>
      <div className="row ra-form-container-row">
        <div className="col-12 col-md-6 ra-form-container-inner">
          <h3>Thank you for joining us! Please fill in your account detail.</h3>
          <div className="row form-group m-t-30">
            <div className="col-2">
              First Name:
            </div>
            <div className="col-12 col-md-6">
              <Input value={createData.firstName} onChange={e => setCreateData({ ...createData, firstName: e.target.value })} />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-2">
              Last Name:
            </div>
            <div className="col-12 col-md-6">
              <Input value={createData.lastName} onChange={e => setCreateData({ ...createData, lastName: e.target.value })} />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-2">
              Email:
            </div>
            <div className="col-12 col-md-6">
              <Input value={createData.email} onChange={e => setCreateData({ ...createData, email: e.target.value })} />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-2">
              Password:
            </div>
            <div className="col-12 col-md-6">
              <Input type="password" value={createData.password} onChange={e => setCreateData({ ...createData, password: e.target.value })} />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-2" />
            <div className="col-12 col-md-6 d-flex justify-content-end">
              <Button submit green>Submit</Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
};

export default withRouter(observer(InviteScreen));
