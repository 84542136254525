import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Container, Button, Select, AddNewGroupModal } from 'components';
import moment from 'moment';
import './SMSTemplate.scss';
import Store from 'store';
import { Eye, Edit } from 'imgs/SVG';

const v = require('voca');

const SMSTemplateList = (props) => {
  const [list, setList] = useState([]);

  const getList = async () => {
    const [res, err] = await Store.SMSTemplate.list();
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    setList(res.data);
  };

  useEffect(() => {
    getList();
  }, [window.location.pathname]);

  const goToDetail = ({ id, name }) => {
    props.history.push(`/sms-template/${id}/${v.kebabCase(name.toLowerCase())}`);
  };

  const goToCreate = () => {
    props.history.push(`/create-sms-template`);
  };

  return (
    <Container>
      <div className="group-container">
        <section style={{ padding: 30 }}>
          {!Store.User.viewOnly() &&
          <div>
            <Button grey className="float-right" onClick={() => goToCreate()}>
              Add New
            </Button>
            <div style={{ clear: 'both' }} />
          </div>}
          <div className="d-flex align-items-center mt-3">
            <h3 className="heading mr-4">SMS Templates</h3>
          </div>
        </section>
        <section className="mt-3">
          {list.map((val, i) => (
            <div className="list-item-row" key={"lisst-item-"+i+val.id}>
              <div className="row d-flex align-items-center">
                <div className="col-12 col-md-4">{val.name}</div>
                <div className="col-12 col-md-4">Created Date: {moment(val.createdAt).format('YYYY-MM-DD HH:mm:ss')}</div>
                <div className="col-12 col-md-4">
                  <Edit onClick={() => {
                    goToDetail(val)
                  }} style={{ fill: '#466EA3', width: 25, cursor: 'pointer' }} />
                </div>
              </div>
            </div>
          ))}
        </section>
      </div>
    </Container>
    
  );
}

export default withRouter(observer(SMSTemplateList));
