import React, { useState, useEffect } from 'react';
import Store from 'store';
import Modal from '../modal/Modal';
import Button from '../button/Button';
import './EditContactModal.scss';

import FormElement from '../form-element/FormElement';

interface Props {
  open: boolean;
  onClose(): void;
  onAdd?(data : any): void;
  detail: any;
}

const AddContactModal = (props: Props) => {

  const [multipleData, setMultipleData] = useState('');

  const [edittedData, setEdittedData] : any = useState({
    name : '',
    phone: '',
    email: '',
    enable: 'NO',
  });
  
  const [isMultiple, setIsMultiple] = useState(false);

  const [formData, setFormData] = useState([
    {
      "name": "Name",
      "isRequired": true,
      "type": "SINGLE_LINE_TEXT",
      "options": "",
    }, {
      "name": "Phone",
      "isRequired": true,
      "type": "SINGLE_LINE_TEXT",
      "options": "",
    }, {
      "name": "Email",
      "isRequired": true,
      "type": "SINGLE_LINE_TEXT",
      "options": "",
    }, {
      "name": "Enable",
      "isRequired": false,
      "type": "DROPDOWN",
      "options": "NO|YES",
    }
  ]);

  useEffect(() => {
    if (!props.open) {
      setEdittedData({});
    } else {
      console.log('props.detail', props.detail)
    }
  }, [props.open]);

  const onSubmit = async () => {
    const values = { ...edittedData };
    const name = values.Name || values.name || '';
    const phone = values.Phone || values.phone || '';
    const email = values.Email || values.email || '';
    const enable = values.Enable === 'YES' ? true : false;
    const [res, err] = await Store.Contact.add({ name, phone, email, groupId: props.detail.id, enable });
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    props.onAdd(res.data);
    props.onClose();
  };

  const onSubmitMultiple = async () => {
    if (!multipleData) {
      return alert('Please check your input content again');
    }
    const contactParts = multipleData.split('\n').map(val => val.trim()).map(val => val.split('|').map(val => val.trim()));
    for (let i=0; i<contactParts.length; i++) {
      if (contactParts[i].length !== 4) {
        return alert('Please check your input content again');
      }
    }
    const contacts = contactParts.map(val => ({
      name: val[0],
      email: val[1],
      phone: val[2],
      enable: val[3] === 'YES' ? true : false,
      groupId: props.detail.id,
    }));
    // console.log(contacts);
    const [res, err] = await Store.Contact.addMultiple({ contacts });
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    props.onAdd(res.data);
    props.onClose();
  }

  if (isMultiple) {
    return (
      <Modal open={props.open} onClose={props.onClose}>
        <h3>Add Contact</h3>
        <div className="list-tracking-container mt-2" style={{ minHeight: 450 }}>
          <FormElement
            isRequired
            options=""
            name="Name | Email | Phone | Enable (YES/NO) each line"
            type="MULTI_LINES_TEXT"
            value={multipleData}
            onChange={value => {
              setMultipleData(value);
            }}
          />
          <div className="form-group">
            <Button green onClick={onSubmitMultiple}>Submit</Button>
          </div>
        </div>
        <p onClick={() => setIsMultiple(false)} style={{ fontSize: 10, margin: 0, marginTop: 5, textDecoration: 'underline', cursor: 'pointer' }}>Add single contacts</p>
      </Modal>
    )
  }

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <h3>Add Contact</h3>
      <div className="list-tracking-container mt-2" style={{ minHeight: 450 }}>
        {!!formData && formData.map((field, index) => {
          return (
            <FormElement
              key={"field"+index}
              {...field}
              value={edittedData[field.name] || edittedData[field.name.toLowerCase()]}
              onChange={value => {
                setEdittedData({
                  ...edittedData,
                  [field.name]: value,
                })
              }}
            />
          );
        })}
        <div className="form-group">
          <Button green onClick={onSubmit}>Submit</Button>
        </div>
      </div>
      <p onClick={() => setIsMultiple(true)} style={{ fontSize: 10, margin: 0, marginTop: 5, textDecoration: 'underline', cursor: 'pointer' }}>Add multiple contacts</p>
    </Modal>
  );
};

export default AddContactModal;
