import React, { useState, useRef } from 'react';
import Store from 'store';
import Modal from '../modal/Modal';
import Button from '../button/Button';
import Input from '../input/Input';
import Select from '../select/Select';
import moment from 'moment';
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";

interface Props {
  open: boolean;
  list: Array<{name : string, id : string}>;
  groupId: string;
  type: string;
  onClose(): void;
  onCreated?(): void;
}

const AddNewScheduleModal = (props : Props) => {
  const [timeType, setTimeType] = useState('SEND_AT');
  const [entityId, setEntityId] = useState(null);
  const [entityName, setEntityName] = useState('');
  const [contactType, setContactType] = useState('normal');
  const [sendAt, setSendAt] = useState();
  const [sendAtExactTime, setSendAtExactTime] = useState(undefined);
  const [intervalData, setIntervalData] = useState({ days: 0, hours: 0, mins: 0 });
  const [repeatData, setRepeatData] = useState({ repeat: '', repeatWhen: '', repeatAt: ''  });
  const [triggerType, setTriggerType] = useState('Form Response');
  const [useTwilio, setUseTwilio] = useState(false);
  
  const smsProviderOptions = [
    { label: 'AQL', value: false, },
    { label: 'Twilio', value: true },
  ]

  const emailOptions = props.list.map(val => ({ value: val.id, label: val.name }));
  const contactTypeOptions = [
    { label: 'Normal Contacts', value: 'normal' },
    { label: 'Duplicated Contacts', value: 'duplicated' },
  ];

  const createSchedule = async (e) => {
    e.preventDefault();
    if (!entityId || !entityName || !contactType || !timeType) return alert('Please fill all necessary forms');
    const obj : any = {
      contactType,
      entityId: entityId,
      entityName,
      groupId: props.groupId,
      entityType: props.type,
      triggerType,
      useTwilio,
    };
    if (timeType === 'SEND_AT') {
      if (sendAt !== undefined) {
        obj.sendAt = sendAt || 0;
        obj.triggerType = 'Form Response';
      } else if (!!sendAtExactTime && !sendAtExactTime.isValid) {
        console.log('sendAtExactTime', sendAtExactTime);
        return alert('Please check your input date and make sure it follows correct format: DD/MM/YYYY hh:mm AM/PM');
      } else if (!!sendAtExactTime) {
        obj.triggerType = 'Newsletter';
        obj.sendAtExactDate = sendAtExactTime.format();
      }
    } else if (timeType === 'INTERVAL') {
      obj.triggerType = 'Form Response';
      obj.autoIntervalDays = intervalData.days;
      obj.autoIntervalHours = intervalData.hours;
      obj.autoIntervalMins = intervalData.mins;
    } else if (timeType === 'REPEAT') {
      obj.triggerType = 'Newsletter';
      const { repeat, repeatWhen, repeatAt } = repeatData;
      if (!repeat || !repeatWhen || !repeatAt) {
        return alert('Please fill all necessary forms');
      }
      if (!moment(repeatAt, 'HH:mm').isValid()) {
        return alert('Please use correct time format hh:mm');
      }
      obj.repeat = repeat;
      obj.repeatWhen = repeatWhen;
      obj.repeatAt = repeatAt;
    }

    const [res, err] = await Store.Schedule.add(obj);
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    props.onClose();
    !!props.onCreated && props.onCreated();
  };

  const weekdays = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ].map(val => ({ value: val, label: val }));

  const dateTimePickerInputRef = useRef(Math.random());

  return (
    <Modal open={props.open} onClose={props.onClose} containerStyle={{ width: '70vw' }}>
      <form className="form-group" onSubmit={createSchedule}>
        <h3 className="heading">Add {props.type} to group</h3>
        <p>Select the {props.type} and the time at which it should send.</p>

        <div className="form-group mt-3">
          <div className="row">
            <div className="col-12 col-md-2 p-l-40">
              {props.type === 'email' ? 'Email' : 'SMS'}
            </div>
            <div className="col-12 col-md-10">
              <Select
                options={emailOptions}
                required
                value={!entityId ? null : emailOptions.find(val => val.value === entityId)}
                onChange={data => {
                  setEntityId(data.value);
                  setEntityName(data.label);
                }}
              />
            </div>
          </div>
          {props.type === 'sms' && (
             <div className="row mt-2">
                <div className="col-12 col-md-2 p-l-40">
                  Send Via
                </div>
                <div className="col-12 col-md-10">
                  <Select
                    options={smsProviderOptions}
                    required
                    value={smsProviderOptions.find(val => val.value == useTwilio)}
                    onChange={data => {
                      setUseTwilio(data.value);
                    }}
                  />
                </div>
              </div>
            )}
          <div className="row mt-2">
            <div className="col-12 col-md-2 p-l-40">
              Contact
            </div>
            <div className="col-12 col-md-10">
              <Select
                options={contactTypeOptions}
                value={contactTypeOptions.find(val => val.value === contactType)}
                required
                onChange={data => setContactType(data.value)}
              />
            </div>
          </div>

          <hr className="hr" />

          <div className="row mt-4">
            <div className="col-12 col-md-2">
              <label className="d-flex align-items-center">
                <input type="radio" className="m-l-5 m-r-10" checked={timeType === 'SEND_AT'} onChange={e => { if (e.target.checked) setTimeType('SEND_AT') }} />
                Send At:
              </label>  
            </div>
            <div className="col-12 col-md-10">
              <Input value={sendAt} onChange={e => {
                dateTimePickerInputRef.current = Math.random();
                setSendAt(+e.target.value);
                setSendAtExactTime(undefined);
                // if (dateTimePickerInputRef.current) {
                //   console.log(dateTimePickerInputRef.current.value)
                //   dateTimePickerInputRef.current.value = "";
                // }
              }} type="number" disabled={timeType !== 'SEND_AT'} placeholder="hours after submission, default: 0 hr" />
              {/* <Input className="mt-2" value={sendAtExactTime} onChange={e => setSendAtExactTime(e.target.value)} disabled={timeType !== 'SEND_AT'} placeholder="or Exact Time: dd/mm/yyyy hh:mm:ss" /> */}
              <div className="mt-2">
                <Datetime
                  value={sendAtExactTime}
                  key={dateTimePickerInputRef.current}
                  onChange={(newValue) => {
                    // if (typeof newValue !== 'string') {
                    //   console.log(newValue)
                    // } else setSendAtExactTime(newValue)
                    setSendAtExactTime(newValue)
                  }}
                  dateFormat={'DD/MM/YYYY'}
                  inputProps={{
                    placeholder: 'or Exact Time',
                  }}
                  renderInput={iProps => <input {...iProps} />}
                />
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 col-md-2">
              <label className="d-flex align-items-center">
                <input type="radio" className="m-l-5 m-r-10" checked={timeType === 'INTERVAL'} onChange={e => { if (e.target.checked) setTimeType('INTERVAL') }} />
                Auto send interval:
              </label>  
            </div>
            <div className="col-12 col-md-10">
              <div className="row">
                <div className="col-12 col-md-4 d-flex align-items-center">
                  <Input className="mr-3" type="number" disabled={timeType !== 'INTERVAL'} value={intervalData.days} onChange={e => setIntervalData({ ...intervalData, days: +e.target.value })} />
                  Days
                </div>
                <div className="col-12 col-md-4 d-flex align-items-center">
                  <Input className="mr-3" type="number" disabled={timeType !== 'INTERVAL'} value={intervalData.hours} onChange={e => setIntervalData({ ...intervalData, hours: +e.target.value })} />
                  Hours
                </div>
                <div className="col-12 col-md-4 d-flex align-items-center">
                  <Input className="mr-3" type="number" disabled={timeType !== 'INTERVAL'} value={intervalData.mins} onChange={e => setIntervalData({ ...intervalData, mins: +e.target.value })} />
                  Mins
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 col-md-2">
              <label className="d-flex align-items-center">
                <input type="radio" className="m-l-5 m-r-10" checked={timeType === 'REPEAT'} onChange={e => { if (e.target.checked) setTimeType('REPEAT') }} />
                Repeat:<br />
                When
              </label>  
            </div>
            <div className="col-12 col-md-10">
              <div className="d-flex align-items-center justify-content-between">

                <div className="d-flex align-items-center">
                  <label>
                    <input type="radio" className="m-r-10" disabled={timeType !== 'REPEAT'} checked={repeatData.repeat === 'weekly'} onChange={e => { if(e.target.checked) setRepeatData({ ...repeatData, repeat: 'weekly' }) }} />
                    Weekly
                  </label>
                </div>
                <div className="d-flex align-items-center">
                  <label>
                    <input type="radio" className="m-r-10" disabled={timeType !== 'REPEAT'} checked={repeatData.repeat === 'fortnightly'} onChange={e => { if(e.target.checked) setRepeatData({ ...repeatData, repeat: 'fortnightly' }) }} />
                    Fortnightly
                  </label>
                </div>
                <div className="d-flex align-items-center">
                  <label>
                    <input type="radio" className="m-r-10" disabled={timeType !== 'REPEAT'} checked={repeatData.repeat === 'monthly'} onChange={e => { if(e.target.checked) setRepeatData({ ...repeatData, repeat: 'monthly' }) }} />
                    Monthly
                  </label>
                  
                </div>
                <div className="d-flex align-items-center">
                  <label>
                    <input type="radio" className="m-r-10" disabled={timeType !== 'REPEAT'} checked={repeatData.repeat === 'yearly'} onChange={e => { if(e.target.checked) setRepeatData({ ...repeatData, repeat: 'yearly' }) }} />
                    Yearly
                  </label>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 col-md-6">
                  <Select
                    options={weekdays}
                    placeholder="Select weekday"
                    isDisabled={timeType !== 'REPEAT'}
                    value={weekdays.find(val => val.value === repeatData.repeatWhen)}
                    onChange={data => setRepeatData({
                      ...repeatData,
                      repeatWhen: data.value,
                    })}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    placeholder="hh:mm"
                    disabled={timeType !== 'REPEAT'}
                    value={repeatData.repeatAt}
                    onChange={e => setRepeatData({
                      ...repeatData,
                      repeatAt: e.target.value,
                    })}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <hr className="hr" />

          <div className="mt-4">
            <p>Type of Schedule:</p>
            <div>
              <label className="d-flex align-items-center">
                <input type="radio" className="m-l-5 m-r-10" checked={triggerType === 'Form Response'} onChange={e => { if (e.target.checked) setTriggerType('Form Response') }} />
                Form Response: After each contact is created, only apply to newly created contacts
              </label>  
            </div>
            <div>
              <label className="d-flex align-items-center">
                <input type="radio" className="m-l-5 m-r-10" checked={triggerType === 'Newsletter'} onChange={e => { if (e.target.checked) setTriggerType('Newsletter') }} />
                For all contacts: Send message to all existing contacts
              </label>  
            </div>
          </div> */}
        </div>
        
        <div className="form-group mt-3">
          <Button submit green className="float-right" style={{ width: 200 }}>
            ADD
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default AddNewScheduleModal;
