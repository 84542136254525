import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Input, Select, Button, EditContactModal, AddContactModal, DuplicateContactModal, TrackingOneUserDataModal } from 'components';
import Store from 'store';
import * as SVG from 'imgs/SVG';
import moment from 'moment';
import classnames from 'classnames';
import LinesEllipsis from 'react-lines-ellipsis';
import TextTruncate from 'react-text-truncate';
import {
  Tooltip,
} from 'react-tippy';

import './Contact.scss';

const Contact = ({ detail, onChangeContact, searchResults }) => {

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [duplicateModalData, setDuplicateModalData] = useState({
    open: false,
    contactId: 0,
    groupId: 0,
  });
  const [trackingModalData, setTrackingModalData] : any = useState({
    open: false,
    data: {},
  })
  const [editData, setEditData] : any = useState({});
  const [contactType, setContactType] = useState('normal');

  const contactList = searchResults.length > 0 ? searchResults : detail.contact;

  const numberOfColumn = (() => {
    let columns = 0;
    if (!contactList) return columns;
    contactList.forEach(val => {
      if (Object.keys(val.fields).length > columns) columns = Object.keys(val.fields).length;
    });
    return columns;
  })();

  const dummyColumnArray = new Array(numberOfColumn).fill(1);

  const toggleEnableContact = async (checked, val, index) => {

    const [res, err] = await Store.Contact.toggleEnable({ id: val.id, enable: checked })
    if (err) {
      console.log('err', err);
      return alert("Error: " + String(err));
    }
    if (!res.success) {
      return alert("Error: " + String(res.message));
    }
    const newContact = contactList.slice();
    newContact[index].enable = checked;
    onChangeContact(newContact);

  };

  const showDuplicateContactModal = (contact) => {
    setDuplicateModalData({ ...duplicateModalData, open: true, contactId: contact.id });
  }

  const removeContact = async (val, index) => {
    // @ts-ignore
    const shouldRemove = window.confirm('Are you sure you want to delete this contact?');
    if (!shouldRemove) return;
    const [res, err] = await Store.Contact.remove({ id: val.id })
    if (err) {
      console.log('err', err);
      return alert("Error: " + String(err));
    }
    if (!res.success) {
      return alert("Error: " + String(res.message));
    }
    const newContact = contactList.slice();
    onChangeContact([
      ...newContact.slice(0, index),
      ...newContact.slice(index+1, newContact.length),
    ]);
  }

  const onEditContact = async (val) => {
    console.log("onEditContact", val);
    const newContact = contactList.slice();
    const findIndex = newContact.findIndex(c => c.id === val.id);
    if (findIndex === -1) return;
    newContact[findIndex] = val;
    onChangeContact(newContact);
  }

  const onAddContact = async (val) => {
    if (Array.isArray(val)) {
      const newList = [
        ...contactList,
        ...val,
      ];
      onChangeContact(newList);
    } else {
      const newContact = contactList.slice();
      newContact.unshift(val);
      onChangeContact(newContact);
    }
  }

  const changeContactType = async (type) => {
    if (type === contactType) return;
    setContactType(type);
    let funcName;
    if (type === 'archived') {
      funcName = 'listArchived';
    } else if (type === 'duplicated') {
      funcName = 'listDuplicated';
    } else if (type === 'normal') {
      funcName = 'listNormal';
    }

    const [res, err] = await Store.Contact[funcName](detail.id, detail.contact);
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    onChangeContact(res.data);
  }

  return (
    <section className="mt-3 p-l-30">
      <div className='print-none'>
        <span onClick={() => setOpenAddModal(true)} className={classnames("span-btn")}>Add a contact</span>
        <span onClick={() => changeContactType('normal')} className={classnames("span-btn", contactType === 'normal' ? 'active' : '')}>Show normal contacts</span>
        <span onClick={() => changeContactType('duplicated')} className={classnames("span-btn", contactType === 'duplicated' ? 'active' : '')}>Show duplicated contacts</span>
        <span onClick={() => changeContactType('archived')} className={classnames("span-btn", contactType === 'archived' ? 'active' : '')}>Show archived contacts</span>
      </div>
      <table className="table contact-table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Date Added</th>
            <th scope="col">Name</th>
            <th scope="col">Phone</th>
            <th scope="col">Email</th>
            <th scope="col" className='print-none'>Enable?</th>
            {/* <th scope="col">Duplicate?</th> */}
            {detail.department && detail.department.name === "Tenant" && <th scope="col">Door Code/Key No</th>}
            {/* {dummyColumnArray.map((val, i) => <th scope="col" key={"empty-column-"+i}></th>)} */}
            <th scope="col" className='print-none'></th>
          </tr>
        </thead>
        <tbody>
          {!!contactList && contactList
            .filter(val => contactType === 'normal' ? !val.isDuplicated : true)
            .map((val, i) => (
            <tr key={"contact-item-" + i}>
              <th scope="row">{i+1}</th>
              <td>{moment(val.createdAt).format('HH:mm / DD-MM-YYYY')}</td>
              <td>{val.name}</td>
              <td>{val.phone}</td>
              <td>{val.email}</td>
              <td className='print-none'>
                <label>
                  <input className="mr-3" type="checkbox" checked={val.enable} onChange={e => toggleEnableContact(e.target.checked, val, i)} disabled={Store.User.viewOnly()} />
                </label>
              </td>
              {/* <td>
                <label>
                  <input className="mr-3" type="checkbox" onChange={() => showDuplicateContactModal(val)} disabled={Store.User.viewOnly()} />
                </label>
              </td> */}
              {detail.department && detail.department.name === "Tenant" && <th scope="col">{val.doorCode}</th>}
              <td className='print-none'>
                {/* <SVG.Eye onClick={() => {
                  console.log(val);
                }} style={{ fill: '#44B0D1', width: 25, cursor: 'pointer', marginRight: 5, }} /> */}
                {!Store.User.viewOnly() && (
                  <SVG.Edit onClick={() => {
                    setEditData(val);
                    setOpenEditModal(true);
                  }} style={{ fill: '#44B0D1', width: 25, cursor: 'pointer', marginRight: 5, }} />
                )}
                <SVG.Send onClick={() => {
                  setTrackingModalData({
                    open: true,
                    data: val,
                  })
                  console.log(val);
                }} style={{ fill: '#44B0D1', width: 25, cursor: 'pointer', marginRight: 5, marginBottom: 3, transform: 'rotate(-45deg)' }} />
                {!Store.User.viewOnly() && <i className="fa fa-clipboard" style={{ color: '#44B0D1', fontSize: 20, width: 28, transform: 'translateY(-5px)', cursor: 'pointer' }} onClick={() => showDuplicateContactModal(val)} />}
                {!Store.User.viewOnly() && (
                  <SVG.Remove onClick={() => removeContact(val, i)} style={{ fill: '#44B0D1', width: 25, cursor: 'pointer' }} />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <EditContactModal
        open={openEditModal}
        onClose={() => setOpenEditModal(false)}
        data={editData}
        detail={detail}
        onEdit={onEditContact}
      />
      <AddContactModal
        open={openAddModal}
        onClose={() => setOpenAddModal(false)}
        detail={detail}
        onAdd={onAddContact}
      />
      <DuplicateContactModal
        open={duplicateModalData.open}
        onClose={() => setDuplicateModalData({ ...duplicateModalData, open: false })}
        contactId={duplicateModalData.contactId}
      />
      <TrackingOneUserDataModal
        open={trackingModalData.open}
        onClose={() => setTrackingModalData({ ...trackingModalData, open: false })}
        data={trackingModalData.data}
      />
    </section>
  );
};

export default Contact;