import React, { useState, useRef } from 'react';
import Store from 'store';
import {
  Modal, Button, Input, 
} from 'components';

const moment = require('moment');

interface Props {
  open: boolean;
  complianceData: any;
  onClose(): void;
  onUploaded?(): void;
}

const UploadFileModal = (props : Props) => {
  const [createData, setCreateData] = useState({
    title: '',
    date: moment().format('YYYY/MM/DD HH:mm:ss'),
    url: '',
    fileName: '',
  });

  const submitUpload = async () => {
    if (!createData.title || !createData.url) {
      return alert('Please upload and fill all the fields');
    }
    const { files } = props.complianceData;
    files.push(createData);
    const [res, err] = await Store.Compliance.upsert({
      ...props.complianceData,
      files,
    });
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    props.onClose();
    setCreateData({
      title: '',
      date: moment().format('YYYY/MM/DD HH:mm:ss'),
      url: '',
      fileName: '',
    });
    !!props.onUploaded && props.onUploaded();
  }

  const refInput = useRef(null);

  const onUploadChange = async (e) => {
    if (e.target.files.length === 0) return;
    const [res, err] = await Store.User.upload(e.target.files[0]);
    if (err) return alert('ERR: ' + String(err));
    if (res.url) {
      setCreateData({
        ...createData,
        url: res.url,
        fileName: res.fileName,
      });
    }
  };

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <form className="form-group">
        <h3 className="heading">Upload a file</h3>
        <div className="form-group mt-3">
          <Input placeholder="Title" value={createData.title} onChange={e => setCreateData({ ...createData, title: e.target.value })} />
        </div>
        <div className="form-group mt-3">
          <Input placeholder="Date" value={createData.date} onChange={e => setCreateData({ ...createData, date: e.target.value })} />
        </div>
        <div className="form-group mt-3 position-relative">
          <Input placeholder="Upload File" value={createData.fileName} readOnly onChange={e => setCreateData({ ...createData, url: e.target.value })} />
          <div
            onClick={() => refInput.current.click()}
            style={{ cursor: 'pointer', position: 'absolute', top: '50%', right: 8, padding: '2px 6px', backgroundColor: '#DEDEDE', borderRadius: 8, transform: 'translateY(-50%)', fontSize: 12, }}
          >
            Upload
          </div>
          <input ref={refInput} type="file" className="d-none" onChange={onUploadChange} />
        </div>
        <div className="form-group mt-3">
          <Button green className="float-right" style={{ width: 200 }} onClick={submitUpload}>
            Upload
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default UploadFileModal;
