import { observable, autorun } from "mobx";
import Request from "./Request.Utils";
import { HOST } from 'const';
import StoreClass from './StoreClass.Utils';
import erria from 'erria/decorator';

class UserStore extends StoreClass {
  constructor() {
    super();
    this.loadInitialData();
  }

  @observable userData : any = {
    
  };

  @observable token = "";

  loadInitialData = async () => {
    let userData = await this.persist.sync("user_data", "object");
    if (userData) {
      this.userData = userData;
    }

    let token = await this.persist.sync("user_token", "string");
    if (token) {
      this.token = token;
      Request.setToken(token);
    }

    autorun(()=>{
      this.persist.save(this.userData, "user_data");
      this.persist.save(this.token, "user_token");
    });
    this.makeReady();
  }

  isAuthen = () => !!this.token;

  viewOnly = () => this.userData.viewOnly;
  
  @erria
  async login({ email, password }) {
    const res = await Request.post('/admins/login', { email, password });
    if (res.data.success && res.data.token) {
      this.token = res.data.token;
      Request.setToken(res.data.token);
      this.userData = res.data.user;
    }
    return res.data;
  }

  @erria
  async recoverPassword({ email, password, code }) {
    const res = await Request.post('/admins/recover-password', { email, password, code });
    return res.data;
  }

  @erria
  async requestCodeRecoverPassword({ email }) {
    const res = await Request.post('/admins/request-code-recorver-password', { email });
    return res.data;
  }

  @erria
  async detail(id) {
    const res = await Request.get('/admins/detail/' + id, {});
    return res.data;
  }

  @erria
  async upload(file) {
    const res = await Request.upload(file);
    return res.data;
  }

  @erria
  async listAdmin() {
    const res = await Request.get('/admins/list', { });
    return res.data;
  }

  @erria
  async createAdmin({ firstName, lastName, email, password, viewOnly, id, formsAccess = '' }) {
    const res = await Request.post('/admins/create', { firstName, lastName, email, password, viewOnly, id, formsAccess });
    return res.data;
  }

  @erria
  async removeAdmin(id) {
    const res = await Request.post('/admins/remove', { id });
    return res.data;
  }

  @erria
  async createAdminWithToken({ firstName, lastName, email, password, token }) {
    const res = await Request.post('/admins/create-with-token', { firstName, lastName, email, password, token });
    return res.data;
  }

  @erria
  async createInviteLink(viewOnly, formsAccess = '') {
    const res = await Request.post('/admins/create-invite-link', { viewOnly, formsAccess });
    return res.data;
  }

  @erria
  async getInviteLinkDetail(token) {
    const res = await Request.get('/admins/invite/' + token, {  });
    return res.data;
  }

  @erria
  async getPhoneAndEmailSettings() {
    const res = await Request.get('/settings', {  });
    return res.data;
  }

  @erria
  async updatePhoneAndEmailSettings(data) {
    const res = await Request.post('/settings/update', data);
    return res.data;
  }

  @erria
  async getContactFormSetting() {
    const res = await Request.get('/settings/contact-form', {});
    return res.data;
  }

  @erria
  async updateContactFormSettings(data) {
    const res = await Request.post('/settings/contact-form/update', data);
    return res.data;
  }

  canAccessThisGroupForm(groupId) {
    const { formsAccess } = this.userData;
    if (!formsAccess) return true;
    const formsAccessIDs = formsAccess.split('.').filter(val => !!val).map(val => +val);
    if (formsAccessIDs.length === 0) return true;
    return formsAccessIDs.includes(groupId);
  }
}

export default new UserStore();

