import React from 'react';
import { Container } from 'components';
import { Redirect } from 'react-router-dom'

import './Home.scss';

export default () => {
  return (
    <Container>
      <Redirect to="/departments/applications" />
    </Container>
  )
};
