import { observable, autorun } from "mobx";
import Request from "./Request.Utils";
import StoreClass from './StoreClass.Utils';
import erria from 'erria/decorator';

class ContactStore extends StoreClass {
  constructor() {
    super();
    this.loadInitialData();
  }

  loadInitialData = async () => {
    this.makeReady();
  }

  @erria
  async toggleEnable({ id, enable }) {
    const res = await Request.post('/contact/toggle-enable', { id, enable });
    return res.data;
  }

  @erria
  async remove({ id }) {
    const res = await Request.post('/contact/remove', { id });
    return res.data;
  }

  @erria
  async add({ name, phone, email, groupId, enable }) {
    const res = await Request.post('/contact/add', { name, phone, email, groupId, enable });
    return res.data;
  }

  @erria
  async addMultiple({ contacts }) {
    const res = await Request.post('/contact/add-multiple', { contacts });
    return res.data;
  }

  @erria
  async update({ id, name, phone, email, fields, doorCode = '' }) {
    const res = await Request.post('/contact/update', { id, name, phone, email, fields, doorCode });
    return res.data;
  }

  @erria
  async search({ keyword, groupId }) {
    const res = await Request.post('/contact/search', { keyword, groupId });
    if (res.data.data) {
      res.data.data = res.data.data.map(val => {
        const fields = !val.fields ? [] : JSON.parse(val.fields);
        val.fields = fields;
        return val;
      });
    }
    return res.data;
  }

  @erria
  async duplicate({ contactId, groupId }) {
    const res = await Request.post('/contact/duplicate', { contactId, groupId });
    return res.data;
  }

  @erria
  async listArchived(id, contacts) {
    const res = await Request.get('/contact/list-archived/'+id, {});
    if (res.data.data) {
      res.data.data = res.data.data.map(val => {
        const fields = !val.fields ? [] : JSON.parse(val.fields);
        val.fields = fields;
        return val;
      });
    }
    return res.data;
  }

  @erria
  async listNormal(id, contacts) {
    const res = await Request.get('/contact/list-normal/'+id, {});
    if (res.data.data) {
      res.data.data = res.data.data.map(val => {
        const fields = !val.fields ? [] : JSON.parse(val.fields);
        val.fields = fields;
        return val;
      });
    }
    return res.data;
  }

  @erria
  async listDuplicated(id, contacts) {
    const res = await Request.get('/contact/list-duplicated/'+id, {});
    if (res.data.data) {
      res.data.data = res.data.data.map(val => {
        const fields = !val.fields ? [] : JSON.parse(val.fields);
        val.fields = fields;
        return val;
      });
    }
    return res.data;
  }
}

export default new ContactStore();

