import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Container, Button, Select, AddNewGroupModal, Input, Notes } from 'components';
import imgLogoFinal from 'imgs/final-logo.jpg';
import imgPrintIcon from 'imgs/print-icon.png';
import './GroupDetail.scss';

import Form from './Form';
import Contact from './Contact';
import Schedule from './Schedule';
import EmailNotification from './EmailNotification';
import Store from 'store';

const GroupDetail = (props) => {

  const [detail, setDetail]: any = useState({});
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const groupParts = [
    { value: 1, label: 'Contacts' },
    { value: 2, label: 'Schedule' },
    Store.User.canAccessThisGroupForm(detail.id) ? { value: 3, label: 'Form' } : undefined,
    // { value: 4, label: 'Logs' },
    { value: 5, label: 'Notification' },
  ].filter(val => !!val);

  const [part, setPart] = useState({ value: 1, label: 'Contacts' });
  const [showCreateModal, setShowCreateModal] = useState(false);

  const getGroupDetail = async () => {
    const id = props.match.params.id;
    const [res, err] = await Store.Group.groupDetail(id);
    if (err) {
      console.log('err', err);
      return alert("Error: " + String(err));
    }
    if (!res.success) {
      return alert("Error: " + String(res.message));
    }
    console.log(res.data);
    setDetail(res.data);
  }

  const search = async () => {
    if (!searchKeyword) return;
    const [res, err] = await Store.Contact.search({ keyword: searchKeyword, groupId: detail.id });
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    if (res.data) setSearchResults(res.data);
  }

  useEffect(() => {
    getGroupDetail();
  }, [window.location.pathname]);

  let partComponent;
  switch (part.value) {
    case 1: partComponent = (
      <Contact
        detail={detail}
        searchResults={searchResults}
        onChangeContact={contact => {
          if (searchResults.length > 0 && searchKeyword.length > 0) {
            setSearchResults(contact);
            search();
          } else {
            setDetail({
              ...detail,
              contact,
            });
          }
        }}
      />
    ); break;
    case 2: partComponent = (
      <Schedule
        detail={detail}
        onChangeSchedules={() => getGroupDetail()}
      />
    ); break;
    case 3: partComponent = (
      <Form
        detail={detail}
        onSaveFields={() => getGroupDetail()}
        onSaveForms={() => getGroupDetail()}
      />
    ); break;
    case 5: partComponent = (
      <EmailNotification
        detail={detail}
      />
    ); break;
    default: partComponent = null;
  }

  const duplicateGroup = async () => {
    const [res, err] = await Store.Group.duplicateGroup(detail.id);
    console.log([res, err]);
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    const name = props.match.params.name;
    props.history.push(`/group/${res.data.id}/${name}-duplicated`);
  };

  return (
    <Container>
      <div>
        <section style={{ padding: 30 }}>
          {!Store.User.viewOnly() ? (
            <div className='print-none'>
              <Button grey className="float-right" onClick={() => setShowCreateModal(true)}>
                Add New Group
              </Button>
              <Button blue className="float-right mr-3" onClick={duplicateGroup}>
                Duplicate Group
              </Button>
              <Notes className="float-right mr-3" slug={`${props.match.params.id}-${props.match.params.name}`} />
              <img
                src={imgPrintIcon} className="float-right mr-3" alt="Print" style={{ width: 40, height: 40, cursor: 'pointer' }}
                onClick={() => {
                  setTimeout(() => {
                    const rootEl = document.querySelector('#root');
                    const htmlContentEl = document.querySelector('.content-container');
                    if (!htmlContentEl || !rootEl) return;
                    rootEl.innerHTML = `<div class="content-container">${htmlContentEl.innerHTML}</div>`;
                    window.print();
                    window.location.reload();
                  }, 500);
                }}
              />
              <div style={{ clear: 'both' }} />
            </div>
          ) : (
            <div className='print-none'>
              <img
                src={imgPrintIcon} className="float-right mr-3" alt="Print" style={{ width: 40, height: 40, cursor: 'pointer' }}
                onClick={() => {
                  setTimeout(() => {
                    const rootEl = document.querySelector('#root');
                    const htmlContentEl = document.querySelector('.content-container');
                    if (!htmlContentEl || !rootEl) return;
                    rootEl.innerHTML = `<div class="content-container">${htmlContentEl.innerHTML}</div>`;
                    window.print();
                    window.location.reload();
                  }, 500);
                }}
              />
            </div>
          )}
          {/* <h3 className="heading">{detail.name}</h3> */}
          <div className="d-flex align-items-center mt-3 group-title-container">
            <img src={imgLogoFinal} alt="" className="print-only" style={{ width: 200, height: 200, marginRight: 20 }} />
            <h3 className="heading mr-4"><Link to={`/departments/${detail.department ? detail.department.name.toLowerCase() : ''}`} className="group-nav">{!!detail.department ? detail.department.name : ''}</Link> > {detail.name} > {part.label}</h3>
            <Select
              width={250}
              options={groupParts}
              value={part}
              onChange={data => setPart(data)}
              isClearable={false}
              className='print-none'
            />

            {part.value === 1 &&
              <div className="contact-searchbox print-none">
                <div className="input-search print-none">
                  <Input
                    placeholder="Search"
                    value={searchKeyword}
                    onChange={e => setSearchKeyword(e.target.value)}
                    onKeyDown={e => e.keyCode === 13 && search()}
                  />
                  <i className="fa fa-search search-icon cursor" onClick={search} />
                </div>
              </div>}
          </div>
        </section>
        {partComponent}
      </div>
      <AddNewGroupModal
        open={showCreateModal}
        onClose={() => setShowCreateModal(false)}
      />
    </Container>
  );
};

export default withRouter(observer(GroupDetail));
