import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Container, Button, Select, GetInviteLinkModal } from 'components';
import moment from 'moment';
import './ListAdmin.scss';
import Store from 'store';
import { Eye, Edit, Remove } from 'imgs/SVG';

const v = require('voca');

const ListAdmin = (props) => {
  const [list, setList] = useState([]);
  const [openInviteModal, setOpenInviteModal] = useState(false);

  const getList = async () => {
    const [res, err] = await Store.User.listAdmin();
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    setList(res.data);
  };

  useEffect(() => {
    getList();
  }, [window.location.pathname]);

  const goToCreate = () => {
    props.history.push(`/create-user-admin`);
  };

  const goToDetail = (val) => {
    console.log(val);
    props.history.push(`/user-admin/${val.id}`);
  }

  const removeUser = async val => {
    const shouldRemove = window.confirm(`Are you sure to remove user: ${val.firstName} ${val.lastName} (${val.email})?`);
    if (!shouldRemove) return;
    const [res, err] = await Store.User.removeAdmin(val.id);
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    else getList();
  }

  return (
    <Container>
      <div className="group-container">
        <section style={{ padding: 30 }}>
          {!Store.User.viewOnly() &&
            <div>
              <Button grey className="float-right" onClick={() => goToCreate()}>
                Add New
            </Button>
              <Button blue className="float-right mr-3" onClick={() => setOpenInviteModal(true)}>
                Get Invite Link
            </Button>
              <div style={{ clear: 'both' }} />
            </div>}
          <div className="d-flex align-items-center mt-3">
            <h3 className="heading mr-4">User Admins</h3>
          </div>
        </section>
        <section className="mt-3">
          {list.map((val, i) => (
            <div className="list-item-row" key={"lisst-item-" + i + val.id}>
              <div className="row d-flex align-items-center">
                <div className="col-12 col-md-3">{val.firstName} {val.lastName}</div>
                <div className="col-12 col-md-3">{val.email}</div>
                <div className="col-12 col-md-3">{val.viewOnly ? 'View Only: YES' : ''}</div>
                <div className="col-12 col-md-3">
                  {!Store.User.viewOnly() &&
                    <Edit onClick={() => {
                      goToDetail(val);
                    }} style={{ fill: '#466EA3', width: 25, cursor: 'pointer' }} />}

                  {!Store.User.viewOnly() &&
                    <Remove onClick={() => {
                      removeUser(val);
                    }} style={{ fill: '#466EA3', width: 25, cursor: 'pointer' }} />}
                </div>
              </div>
            </div>
          ))}
        </section>
      </div>
      <GetInviteLinkModal
        open={openInviteModal}
        onClose={() => setOpenInviteModal(false)}
      />
    </Container>

  );
}

export default withRouter(observer(ListAdmin));
