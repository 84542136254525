import React, { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';
import Store from 'store';
import { Remove, Edit } from 'imgs/SVG';
import Modal from '../modal/Modal';
import Button from '../button/Button';

import imgNotes from 'imgs/notes.png';
import imgPlus from 'imgs/add-image.png';
import imgSmallClose from 'imgs/small-close.png';

import './Notes.scss';

const moment = require('moment');

interface Props {
  className?: string,
  slug: string,
  [key: string]: any,
}

const MODAL_PAGES = {
  LIST: 'LIST',
  CREATE: 'CREATE',
}

const NoteCreate = (props: any) => {
  const { state, setState } = props;
  
  const removeImage = (i) => {
    const newState = Object.assign({}, state);
    newState.noteData.imgs = [...newState.noteData.imgs.slice(0, i), ...newState.noteData.imgs.slice(i+1, newState.noteData.imgs.length)];
    setState(newState);
  }

  const onUploadChange = async (e) => {
    if (e.target.files.length === 0) return;
    const [res, err] = await Store.User.upload(e.target.files[0]);
    if (err) return alert('ERR: ' + String(err));
    if (res.url) {
      setState({
        ...state,
        noteData: {
          ...state.noteData,
          imgs: [
            ...state.noteData.imgs,
            res.url,
          ]
        }
      });
    }
  };

  const refInput = useRef(null);

  return (
    <div className="notes__modal">
      <h2 className="notes__modal__title">Notes</h2>
      <div className="notes__crud">
        <textarea
          placeholder="Type some note.."
          value={state.noteData.content}
          onChange={e => setState({
            ...state,
            noteData: {
              ...state.noteData,
              content: e.target.value,
            }
          })}
        />
        <input ref={refInput} type="file" className="d-none" onChange={onUploadChange} />
        <div className="notes__crud__upload-images">
          {state.noteData.imgs.map((val, i) => (
            <div className="position-relative">
              <img src={val} alt="" key={"item"+i} />
              <img src={imgSmallClose} alt="remove" className="remove-img" onClick={() => removeImage(i)} />
            </div>
          ))}
          <img className="add" alt="add" src={imgPlus} onClick={() => refInput.current.click()} />
        </div>
        <div>
          <Button green className="float-right" onClick={props.onSave}>
            Save
          </Button>
          <div style={{ clear: 'both' }} />
        </div>
      </div>
    </div>
  );
}

const Notes = (props: Props) => {

  const [list, setList] : any = useState([]);

  const [state, setState] : any = useState({
    showModal: false,
    modalPage: MODAL_PAGES.LIST,
    noteData: {
      content: '',
      imgs: [],
    },
  })

  const showNotes = () => {
    setState({ ...state, showModal: true });
  };

  const showAddNewNoteUI = () => {
    setState({
      ...state,
      modalPage: MODAL_PAGES.CREATE,
      noteData: {
        content: '',
        imgs: [],
      },
    });
  };

  const removeNote = async (val, i) => {
    const [res, err] = await Store.Group.deleteNote(val.id);
    if (err) return alert(err);
    if (!res.success) return alert(res.message);
    const newList = [...list.slice(0, i), ...list.slice(i+1, list.length)];
    setList(newList);
  }

  const editNote = async (val, i) => {
    setState({
      ...state,
      modalPage: MODAL_PAGES.CREATE,
      noteData: val,
    })
  }

  const renderModalList = () => {
    return (
      <div className="notes__modal">
        <h2 className="notes__modal__title">Notes</h2>
        <div className="notes__modal__list-container">
          {list.map((val, i) => (
            <div className="notes__single" key={val.id}>
              <div className="content">
                {val.content}
                {val.imgs.length > 0 &&
                <div className="content-images">
                  {val.imgs.map((item, index) => (
                    <img src={item} alt="" key={"img-"+index} onClick={() => window.open(item, '_blank')} />
                  ))}
                </div>}
              </div>
              <div className="timestamp">
                <Edit onClick={() => editNote(val, i)} style={{ fill: '#466EA3', width: 25, cursor: 'pointer', transform: 'translateY(5px)' }} />
                <Remove onClick={() => removeNote(val, i)} style={{ fill: '#466EA3', width: 25, cursor: 'pointer', transform: 'translateY(5px)' }} />
                <br />
                {moment(val.updatedAt).format('Mo MMM / HH:mm')} 
              </div>
            </div>
          ))}
        </div>
        <div>
          <Button green className="float-right" onClick={showAddNewNoteUI}>
            Add New
          </Button>
          <div style={{ clear: 'both' }} />
        </div>
      </div>
    );
  }

  const saveNote = async () => {
    const obj = {
      id: state.noteData.id || 0,
      departmentGroupSlug: props.slug,
      content: state.noteData.content,
      imgs: state.noteData.imgs,
    };
    if (!obj.content) return alert('Please add some content');
    const [res, err] = await Store.Group.upsertNote(obj);
    if (err) return alert('ERR: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    await getData();
    setState({ ...state, modalPage: MODAL_PAGES.LIST });
  }

  const getData = async () => {
    const [res, err] = await Store.Group.listNote(props.slug);
    if (err) return alert('ERR: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    if (res.data) {
      setList(res.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={classnames("notes__wraper", props.className)}>
      <img src={imgNotes} alt="Notes" className="notes__img" onClick={showNotes} />
      <Modal
        open={state.showModal}
        onClose={() => setState({ ...state, showModal: false, modalPage: MODAL_PAGES.LIST })}
        containerClassName="notes__modals__container"
        styles={{
          modal: {
            minWidth: '80vw',
            width: '80vw',
            padding: 15,
            background: '#fff',
            borderRadius: 4,
          },
        }}
      >
        {state.modalPage === MODAL_PAGES.CREATE ? <NoteCreate state={state} setState={setState} onSave={saveNote} /> : 
          state.modalPage === MODAL_PAGES.LIST ? renderModalList() : 
          null
        }
      </Modal>
    </div>
  );
};

export default Notes;