import React, { useState, useEffect } from 'react';
import Store from 'store';
import Modal from '../modal/Modal';
import Button from '../button/Button';
import Input from '../input/Input';

import './EditContactModal.scss';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface Props {
  open: boolean;
  onClose(): void;
  groupId: string;
}

const SendEmailNow = (props: Props) => {
  const [sending, setSending] = useState(false)
  const [createData, setCreateData]: any = useState({});

  const onSubmitForm = async (e) => {
    e.preventDefault();
    
    const { subject, content } = createData;
    if (!subject || !content) {
      return alert('Please fill all form');
    }
    setSending(true);
    const [res, err] = await Store.Schedule.sendEmailNow({
      groupId: props.groupId,
      subject,
      content
    });
    setSending(false);
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    else {
      alert('Sent successfully');
      props.onClose();
    }
  };

  return (
    <Modal open={props.open} onClose={props.onClose} containerStyle={{ width: '70vw' }}>
      <h3>Send Email to all submitted users</h3>
      <div className="mt-2">
        <form className="mt-3 p-l-30" onSubmit={onSubmitForm}>
          <div className="row form-group">
            <div className="col-2">
              Subject:
            </div>
            <div className="col-12 col-md-6">
              <Input value={createData.subject} onChange={e => setCreateData({ ...createData, subject: e.target.value })} />
            </div>
          </div>
          <div className="row form-group" style={{ height: 300 }}>
            <div className="col-2">
              Content:
            </div>
            <div className="col-12 col-md-6">
              {/* <textarea style={{ height: 100, width: '100%', borderRadius: 8, borderColor: '#BFBFBF' }} value={createData.content} onChange={e => setCreateData({ ...createData, content: e.target.value })} /> */}
              {/* <div id="editor" style={{ height: 400 }} /> */}
              <ReactQuill
                theme="snow"
                value={createData.content || ''}
                onChange={text => setCreateData({ ...createData, content: text })}
                modules={{
                  toolbar: {
                    container: [
                      [{ header: [1, 2, false] }],
                      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                      ['bold', 'italic', 'underline'],
                      [{ 'align': [] }],
                      ['link'],
                      ['clean']
                    ],
                  }
                }}
                style={{
                  height: 200,
                }}
              />
            </div>
          </div>

          <p>Tags Available are: [name], [email], [phone]</p>
          <div className="row form-group">
            <div className="col-12">
              <Button submit green>{sending ? 'Sending...': 'Submit'}</Button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default SendEmailNow;
