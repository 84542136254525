import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import Store from 'store';

import './Form.scss';
import { Input, FormElement, Button } from 'components';

const FormScreen = (props) => {

  const [detail, setDetail] : any = useState({});
  const [hasPassword, setHasPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [values, setValues] : any = useState({});
  const [didSubmit, setDidSubmit] : any = useState(false);

  const getFormDetail = async () => {
    const [res, err] = await Store.Form.getFormDetail(window.location.pathname);
    if (err) {
      return alert("ERR: " + String(err))
    }
    if (!res.success) {
      return alert("ERR: " + String(res.message))
    }
    // if (res.hasPassword) {
    //   setHasPassword(true);
    // }
    if (res.data) {
      setDetail(res.data);
    }
    console.log(res);
  }

  useEffect(() => {
    getFormDetail();
  }, [window.location.pathname]);

  const onSubmitForm = async e => {
    e.preventDefault();
    const from = window.location.pathname;
    const name = values.Name || values.name || '';
    const phone = values.Phone || values.phone || '';
    const email = values.Email || values.email || '';
    const newValues = Object.assign({}, values);
    delete newValues.Name;
    delete newValues.name;
    delete newValues.Phone;
    delete newValues.phone;
    delete newValues.Email;
    delete newValues.email;
    const fields = JSON.stringify(newValues);
    const [res, err] = await Store.Form.submitForm({ name, phone, email, fields, from });
    if (err) {
      return alert("ERR: " + String(err))
    }
    if (!res.success) {
      return alert("ERR: " + String(res.message))
    }
    setDidSubmit(true);
  }

  const onSubmitPassword = async e => {
    e.preventDefault();
    if (!password) return alert("Please input password");
    const [res, err] = await Store.Form.submitPassworFormDetail({
      link: window.location.pathname,
      password,
    });
    if (err) {
      return alert("ERR: " + String(err))
    }
    if (!res.success) {
      return alert("ERR: " + String(res.message))
    }
    if (res.data) {
      setDetail(res.data);
      setHasPassword(false);
    }
    console.log(res);
  }

  if (didSubmit) {
    return (
      <div className="ra-form-container pt-3 pb-3" style={{ display: 'block' }}>
        <div className="row ra-form-container-row">
          <div className="col-12 col-md-6 ra-form-container-inner">
            <h3 className="text-center">Thank you for filling out your information!</h3>
          </div>
        </div>
      </div>
    )
  }

  return hasPassword ? (
    <form className="ra-form-container" onSubmit={onSubmitPassword}>
      <div className="row ra-form-container-row">
        <div className="col-12 col-md-6 ra-form-container-inner">
          <Input
            placeholder="Type Password & Enter"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </div>
      </div>
    </form>
  ) : (
    <form className="ra-form-container" onSubmit={onSubmitForm} style={{ display: 'block', padding: '30px 0px' }}>
      <div className="row ra-form-container-row">
        <div className="col-12 col-md-6 ra-form-container-inner">
          {!!detail && !!detail.fields && detail.fields.map((val, i) => (
            <FormElement
              key={"form-element-"+i}
              {...val}
              value={values[val.name]}
              onChange={data => setValues({
                ...values,
                [val.name]: data,
              })}
            />
          ))}
          <hr className="hr" />
          <div className="form-group">
            <Button submit green>Submit</Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default withRouter(observer(FormScreen));
