import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Container, Button, Input } from 'components';
import moment from 'moment';
import classnames from 'classnames';
import './Compliance.scss';
import Store from 'store';
import { Eye, Remove, Edit } from 'imgs/SVG';
import AddNewComplianceModal from './AddNewCompliance.Modal';
import UploadFileModal from './UploadFile.Modal';
import UpdateComplianceModal from './UpdateCompliance.Modal';

const v = require('voca');

const Compliance = (props) => {
  const [list, setList] = useState([]);
  const [toggleDetailIndex, setToggleDetailIndex] = useState(undefined);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [uploadFileModalData, setUploadFileModalData] = useState({
    open: false,
    complianceData: {},
  });
  const [updateModalData, setUpdateModalData] = useState({
    open: false,
    complianceData: {},
  });
  const [searchKeyword, setSearchKeyword] = useState("");

  const getList = async () => {
    const [res, err] = await Store.Compliance.listAll();
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    if (res.data) setList(res.data);
  };

  useEffect(() => {
    getList();
  }, []);

  const showUploadModal = (data) => {
    setUploadFileModalData({
      open: true,
      complianceData: data,
    })
  }

  const removeGroup = async (val) => {
    const shouldRemove = window.confirm(`Are you sure to remove compliance: ${val.addressLine1}`);
    if (!shouldRemove) return;
    const [res, err] = await Store.Compliance.remove(val.id);
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    getList();
  }

  const search = async () => {
    if (!searchKeyword) return;
    const [res, err] = await Store.Compliance.search(searchKeyword);
    if (err) return alert('Error: ' + String(err));
    if (!res.success) return alert('Error: ' + String(res.message));
    if (res.data) setList(res.data);
  }

  return (
    <Container>
      <div className="group-container">
        <section style={{ padding: 30 }}>
          <div>

            {!Store.User.viewOnly() &&
              <Button grey className="float-right" onClick={() => setShowCreateModal(true)}>
                Add New
            </Button>}
            {/* <Button blue className="float-right mr-3">
              View Archived
            </Button> */}
            <div style={{ clear: 'both' }} />
          </div>
          <div className="d-flex flex-column mt-3 roup-title-container">
            <h3 className="heading mr-4">Compliance</h3>
            <div className="compliance-searchbox">
              <span className="search-heading">Search: </span>
              <div className="input-search">
                <Input
                  value={searchKeyword}
                  onChange={e => setSearchKeyword(e.target.value)}
                  onKeyDown={e => e.keyCode === 13 && search()}
                />
                <i className="fa fa-search search-icon cursor" onClick={search} />
              </div>
            </div>
          </div>
        </section>
        <section>
          {list.map((val, i) => (
            <div className="list-item-row" key={"lisst-item-" + i + val.id}>
              <div className="row d-flex align-items-start">
                <div className="col-12 col-md-4 td-group-name">{val.addressLine1}</div>
                <div className="col-12 col-md-4">Last Update: <span className="group-value">{moment(val.updatedAt).year() === 1 ? '' : moment(val.updatedAt).format('HH:mm / DD-MM-YYYY')}</span></div>
                <div className="col-12 col-md-2">Files: <span className="group-value">{val.files.length}</span></div>
                <div className="col-12 col-md-2">
                  <Eye onClick={() => {
                    setToggleDetailIndex(toggleDetailIndex === i ? undefined : i)
                  }} style={{ fill: toggleDetailIndex === i ? 'black' : '#466EA3', width: 25, cursor: 'pointer', marginRight: 20, }} />

                  <i className="fa fa-upload" style={{ color: '#466EA3', fontSize: 20, width: 28, transform: 'translateY(-5px)', cursor: 'pointer' }} onClick={() => showUploadModal(val)} />

                  <Edit onClick={() => {
                    setUpdateModalData({
                      open: true,
                      complianceData: val,
                    })
                  }} style={{ fill: '#466EA3', width: 25, cursor: 'pointer', marginRight: 5, }} />

                  <Remove onClick={() => {
                    removeGroup(val)
                  }} style={{ fill: '#466EA3', width: 25, cursor: 'pointer' }} />
                </div>
              </div>
              {toggleDetailIndex === i && (
                <div className="row compliance-item">
                  <div className="col-12 col-md-4">
                    <div className="mt-2 mb-2 ">
                      <p><b>Address Line 1:</b>  {val.addressLine1}</p>
                      <p><b>Address Line 2:</b>  {val.addressLine2}</p>
                      <p><b>Postal Code:</b>  {val.postCode}</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-8">
                    <p><b>Files:</b></p>
                    {val.files.map((item, index) => (
                      <a key={"item-" + index} href={item.url} target="_blank"><p><i className="fa fa-file-o mr-2" />: {item.title} ({item.date})</p></a>
                    ))}
                  </div>
                </div>

              )}
            </div>
          ))}
        </section>
      </div>
      <AddNewComplianceModal
        open={showCreateModal}
        onClose={() => setShowCreateModal(false)}
        onCreated={() => getList()}
      />
      <UpdateComplianceModal
        open={updateModalData.open}
        data={updateModalData.complianceData}
        onClose={() => setUpdateModalData({
          open: false,
          complianceData: {},
        })}
        onUpdated={() => getList()}
        onOpenUpload={() => showUploadModal(updateModalData.complianceData)}
      />
      <UploadFileModal
        complianceData={uploadFileModalData.complianceData}
        onClose={() => setUploadFileModalData({
          open: false,
          complianceData: {},
        })}
        open={uploadFileModalData.open}
        
      />
    </Container>

  );
}

export default withRouter(observer(Compliance));
