import React, { useState, useRef, useEffect } from 'react';

import Input from '../input/Input';
import Select from '../select/Select';
import './FormElement.scss';
import Button from 'components/button/Button';
import Store from 'store';

const FORMTYPE = {
  SINGLE_LINE_TEXT: 'SINGLE_LINE_TEXT',
  MULTI_LINES_TEXT: 'MULTI_LINES_TEXT',
  NUMBER: 'NUMBER',
  URL: 'URL',
  DATE: 'DATE',
  DROPDOWN: 'DROPDOWN',
  UPLOAD_FILE: 'UPLOAD_FILE',
  MULTI_LINES_DROPDOWN: 'MULTI_LINES_DROPDOWN',
};

const FormElement = ({ value, onChange, type, name, isRequired, options }) => {
  console.log(type);
  const [uploadData, setUploadData] = useState({uploaded: false, files: [] });

  useEffect(() => {
    if (type === FORMTYPE.UPLOAD_FILE && !!value) {
      // console.log(value);
      setUploadData({
        uploaded: true,
        files: value,
      })
    }
  }, [value, type])

  const removeFiles = index => {
    const newUploadData = Object.assign({}, uploadData);
    newUploadData.files = [...newUploadData.files.slice(0, index), ...newUploadData.files.slice(index + 1, newUploadData.files.length)];
    setUploadData(newUploadData);
  }

  const refInput = useRef(null);

  const onUploadChange = async (e) => {
    if (e.target.files.length === 0) return;

    const files = await Promise.all([...e.target.files]);

    const uploadedFiles = [];

    const promiseArr = files.map(async val => {
      const [res, err] = await Store.User.upload(val);
      if (err) return;
      if (res.url) {
        uploadedFiles.push({
          fileName: res.fileName,
          url: res.url,
        })
      }
    });

    await Promise.all(promiseArr);

    setUploadData({
      uploaded: true,
      files: uploadedFiles,
    });
    onChange(uploadedFiles);
  };

  let selectOptions = [];
  if (options) {
    selectOptions = options.split('|').map(val => ({ label: val.trim(), value: val.trim() }));
  }
  let multilinesSelectOptions = [];
  if (options && type === FORMTYPE.MULTI_LINES_DROPDOWN) {
    multilinesSelectOptions = options.split('---').map(val => ({ label: val.trim(), value: val.trim() }));
  }

  const renderLabel = () => <label>{name} {isRequired && '*'}</label>

  let comp;
  switch (type) {
    case FORMTYPE.URL:
    case FORMTYPE.SINGLE_LINE_TEXT:
      comp = (
        <div className="form-group">
          {renderLabel()}
          <Input required={isRequired} value={value} onChange={e => onChange(e.target.value)} />
        </div>
      );
      break;
    case FORMTYPE.MULTI_LINES_TEXT:
      comp = (
        <div className="form-group">
          {renderLabel()}
          <textarea className="textarea input-control input-group" rows={5} required={isRequired} value={value} onChange={e => onChange(e.target.value)} />
        </div>
      );
      break;
    case FORMTYPE.NUMBER:
      comp = (
        <div className="form-group">
          {renderLabel()}
          <Input required={isRequired} value={String(value)} onChange={e => onChange(+e.target.value)} type="number" />
        </div>
      );
      break;
    case FORMTYPE.DROPDOWN:
      comp = (
        <div className="form-group">
          {renderLabel()}
          <Select
            options={selectOptions}
            value={selectOptions.find(val => val.value === value)}
            onChange={data => {
              if (!data) onChange(undefined);
              else onChange(data.value);
            }}
            required={isRequired}
          />
        </div>
      );
      break;
    case FORMTYPE.MULTI_LINES_DROPDOWN:
      comp = (
        <div className="form-group">
          {renderLabel()}
          <Select
            options={multilinesSelectOptions}
            value={multilinesSelectOptions.find(val => val.value === value)}
            onChange={data => {
              if (!data) onChange(undefined);
              else onChange(data.value);
            }}
            required={isRequired}
            components={{
              Option: (oProps: any) => {
                console.log('oProps', oProps);
                const { children, className, cx, getStyles, innerRef, innerProps, isFocused } = oProps;
                return (
                  <div
                    css={getStyles("option", oProps)}
                    className={cx({ menu: true }, className)}
                    style={{
                      padding: 10,
                      borderBottom: '1px solid rgba(0,0,0,0.1)',
                      backgroundColor: isFocused ? 'rgba(0,0,0,0.1)' : undefined,
                      cursor: 'pointer',
                    }}
                    {...innerProps}
                    ref={innerRef}
                  >
                    <pre style={{ fontFamily: 'Open Sans', fontSize: 14, marginBottom: 0 }}>{children}</pre>
                  </div>
                );
              },
              SingleValue: (oProps: any) => {
                const { children, className, cx, getStyles, innerRef, innerProps } = oProps;
                return (
                  <div
                    css={getStyles("singleValue", oProps)}
                    className={cx({ menu: true }, className)}
                    style={{
                      padding: 5,
                    }}
                    {...innerProps}
                    ref={innerRef}
                  >
                    <pre style={{ fontFamily: 'Open Sans', fontSize: 14, marginBottom: 0 }}>{children}</pre>
                  </div>
                );
              }
            }}
          />
        </div>
      );
      break;
    case FORMTYPE.UPLOAD_FILE:
      comp = (
        <div className="form-group">
          {renderLabel()}
          <div>
            <Button blue small onClick={() => refInput.current.click()}>
              <i className="fa fa-upload mr-3" aria-hidden="true"></i> Upload
            </Button>
            {uploadData.uploaded && (
              <div className="mt-2">
                {uploadData.files.map((val, i) => (
                  <p key={'upload-' + i}>
                    <i className="fa fa-file-o" /> <a href={val.url} target="blank">{val.fileName}</a> <i className="fa fa-times pointer" onClick={() => removeFiles(i)} />
                  </p>
                ))}
              </div>
            )}
            <input ref={refInput} multiple required={isRequired} type="file" className="d-none" onChange={onUploadChange} />
          </div>
        </div>
      );
      break;
  }
  return <div className="form-element-container">{comp}</div>;
};

export default FormElement;